import React from 'react';
import { Box, Typography } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';

const UserStatus = ({ user }) => (
    <Box
        display="flex"
        alignItems="center"
        sx={{ backgroundColor: '#f5f5f5', padding: '5px 10px', borderRadius: '20px' }}
    >
        <PersonIcon fontSize="small" sx={{ marginRight: '5px', color: '#393939' }} />
        <Typography variant="body2" color="#393939">
            {user.firstName} {user.lastName}
        </Typography>
    </Box>
);

export default React.memo(UserStatus);
