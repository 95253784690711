// src/hooks/useTrainingDue.jsx
import { useEffect, useState } from 'react';
import { initializeDB } from '../config/db';
import {TRAINING_DUE_STORE} from "../config/constants";


/**
 * Хук для чтения списка TrainingDue из IndexedDB (trainingDue store).
 * Возвращает массив объектов вида:
 *  { id, externalId, fullName, division, course, dateDue, dateThru, ... }
 */
export function useTrainingDue() {
    const [trainingDueList, setTrainingDueList] = useState([]);

    async function fetchTrainingDueList() {
        try {
            const db = await initializeDB();
            const tx = db.transaction(TRAINING_DUE_STORE, 'readonly');
            const store = tx.objectStore(TRAINING_DUE_STORE);

            const allRecords = await store.getAll();
            await tx.done;

            setTrainingDueList(allRecords || []);
        } catch (error) {
            console.error('Error loading trainingDue from IDB:', error);
        }
    }

    useEffect(() => {
        fetchTrainingDueList();

        // При каждом "syncComplete" можно снова перечитать
        const handleSyncComplete = () => {
            fetchTrainingDueList();
        };
        window.addEventListener('syncComplete', handleSyncComplete);

        return () => {
            window.removeEventListener('syncComplete', handleSyncComplete);
        };
    }, []);

    return trainingDueList;
}
