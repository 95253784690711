// serviceWorkerRegistration.js

export function register() {
    if ('serviceWorker' in navigator) {
        window.addEventListener('load', () => {
            navigator.serviceWorker
                .register('/service-worker.js')
                .then((registration) => {
                    console.log('Service Worker registered with scope:', registration.scope);

                    registration.onupdatefound = () => {
                        const installingWorker = registration.installing;
                        if (installingWorker) {
                            installingWorker.onstatechange = () => {
                                if (installingWorker.state === 'installed') {
                                    if (navigator.serviceWorker.controller) {
                                        // Есть старая версия -> Новая версия установлена!
                                        // Сообщим новому воркеру активироваться немедленно
                                        installingWorker.postMessage({ type: 'SKIP_WAITING' });

                                        // Когда контроллер сменится на новый воркер, перезагрузим страницу
                                        navigator.serviceWorker.addEventListener('controllerchange', () => {
                                            window.location.reload();
                                        });
                                    } else {
                                        console.log('Содержимое теперь доступно оффлайн!');
                                    }
                                }
                            };
                        }
                    };
                })
                .catch((error) => {
                    console.error('Service Worker registration failed:', error);
                });
        });
    }
}

export function unregister() {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.ready
            .then((registration) => {
                registration.unregister();
            })
            .catch((error) => {
                console.error(error.message);
            });
    }
}
