import React from 'react';
import { Box, Typography } from '@mui/material';
import SyncIcon from '@mui/icons-material/Sync';

const SyncStatus = ({ lastSyncTime, onSync }) => (
    <Box
        display="flex"
        alignItems="center"
        onClick={onSync}
        sx={{
            backgroundColor: '#f5f5f5',
            padding: '5px 10px',
            borderRadius: '20px',
            cursor: 'pointer',
            '&:hover': {
                backgroundColor: '#e0e0e0',
            }
        }}
    >
        <SyncIcon fontSize="small" sx={{ marginRight: '5px', color: 'blue' }} />
        <Typography variant="body2" color="blue">{lastSyncTime}</Typography>
    </Box>
);

export default React.memo(SyncStatus);
