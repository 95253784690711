import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { useWeeklyInstructions } from '../../hooks/useWeeklyInstructions';
import TBTInstructionTable from './TBTInstructionTable';

const TBTPage = () => {
    const weeklyInstructions = useWeeklyInstructions();
    const navigate = useNavigate();

    const handleViewDetail = (id) => {
        navigate(`/tbt/${id}`);
    };

    return (
        <Box sx={{ p: 4 }}>
            <Link to="/">
                <Button
                    variant="contained"
                    color="success"
                    sx={{ mb: 2 }}
                >
                    Back
                </Button>
            </Link>

            <Typography variant="h4" gutterBottom>
                TBT Instructions
            </Typography>

            <TBTInstructionTable
                weeklyInstructions={weeklyInstructions}
                onViewDetail={handleViewDetail}
            />
        </Box>
    );
};

export default TBTPage;
