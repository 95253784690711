import {Box, Chip, Typography} from "@mui/material";
import React from "react";

export function TbtStatusInfo({ statuses }) {
    // statuses - объект вида { process: 2, finish: 1, ... }
    const hasStatuses = Object.keys(statuses).length > 0;

    if (!hasStatuses) {
        return (
            <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                No instructions
            </Typography>
        );
    }

    return (
        <Box sx={{ mt: 1, display: 'flex', flexWrap: 'wrap', gap: 1 }}>
            {Object.entries(statuses).map(([status, count]) => (
                <Chip
                    key={status}
                    label={`${status}: ${count}`}
                    size="small"
                    color={status === 'process' ? 'warning' : 'default'}
                />
            ))}
        </Box>
    );
}