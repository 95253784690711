import React from 'react';
import { Routes, Route } from 'react-router-dom';
import LoginPage from '../components/LoginPage';

function UnauthenticatedLayout() {
    return (
        <div style={{ width: '100%', height: '100%' }}>
            <Routes>
                <Route path="#/login" element={<LoginPage />} />
                <Route path="*" element={<LoginPage />} />
            </Routes>
        </div>
    );
}

export default UnauthenticatedLayout;
