import { useState, useEffect } from 'react';
import { register } from '../serviceWorkerRegistration';

export default function useServiceWorkerUpdates() {
    const [waitingWorker, setWaitingWorker] = useState(null);

    useEffect(() => {
        register((registration) => {
            const worker = registration.waiting;
            if (worker) {
                setWaitingWorker(worker);
            }
        });
    }, []);

    const updateApp = () => {
        if (waitingWorker) {
            waitingWorker.postMessage({ type: 'SKIP_WAITING' });
            waitingWorker.addEventListener('statechange', (e) => {
                if (e.target.state === 'activated') {
                    window.location.reload();
                }
            });
        }
    };

    return { waitingWorker, updateApp };
}
