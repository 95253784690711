import {Typography} from "@mui/material";
import React from "react";

export function CcStatusInfo({ status }) {
    if (!status) return null; // Если пусто, ничего не выводим
    return (
        <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
            {status}
        </Typography>
    );
}