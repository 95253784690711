import { useState, useEffect } from 'react';
import { initializeDB } from '../config/db';
import { WEEKLY_INSTRUCTION_STORE } from '../config/constants';

export const useInstructionCounts = () => {
    const [processedCount, setProcessedCount] = useState(0);
    const [newCount, setNewCount] = useState(0);
    const [showProcessed, setShowProcessed] = useState(true);

    useEffect(() => {
        const fetchInstructionCounts = async () => {
            try {
                const db = await initializeDB();
                const tx = db.transaction(WEEKLY_INSTRUCTION_STORE, 'readonly');
                const store = tx.objectStore(WEEKLY_INSTRUCTION_STORE);
                const allItems = await store.getAll();

                const processed = allItems.filter(item => item.status === 'process').length;
                const newItems = allItems.filter(item => item.status === 'new').length;

                setProcessedCount(processed);
                setNewCount(newItems);
            } catch (error) {
                console.error('Ошибка при получении данных из IDB:', error);
                setProcessedCount(0);
                setNewCount(0);
            }
        };

        fetchInstructionCounts();

        const handleInstructionStatusChanged = () => {
            fetchInstructionCounts();
        };

        window.addEventListener('instructionStatusChanged', handleInstructionStatusChanged);

        // Интервал для переключения отображения processed/new
        const intervalId = setInterval(() => {
            setShowProcessed(prev => !prev);
        }, 3000);

        return () => {
            window.removeEventListener('instructionStatusChanged', handleInstructionStatusChanged);
            clearInterval(intervalId);
        };
    }, []);

    return { processedCount, newCount, showProcessed };
};
