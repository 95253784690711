import React from 'react';
import { Box, Typography } from '@mui/material';
import ScreenRotationIcon from '@mui/icons-material/ScreenRotation';

function OrientationWarning() {
    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            height="100vh"
            textAlign="center"
            sx={{ backgroundColor: '#f5f5f5', padding: '20px' }}
        >
            <ScreenRotationIcon sx={{ fontSize: 60, color: '#1976d2', marginBottom: '20px' }} />
            <Typography variant="h6" color="textSecondary">
                Please use the device in horizontal orientation for better content display.
            </Typography>
        </Box>
    );
}

export default OrientationWarning;
