import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Box,
    Button,
    TextField,
    Typography,
    FormControlLabel,
    Checkbox,
    Card,
    CardContent,
    Autocomplete,
    InputAdornment,
    Grid,
    Divider,
    Paper
} from '@mui/material';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PersonIcon from '@mui/icons-material/Person';

import Webcam from 'react-webcam';

import { initializeDB } from '../../config/db';
import {
    CC_STORE,
    CODE_GROUP_STORE,
    JOB_STORE,
    EMPLOYEE_STORE
} from '../../config/constants';

import axiosInstance from '../../axiosInstance';
import SelectEmployeeModal from '../training/SelectEmployeeModal';

const normalizeSearch = (str) => {
    if (!str) return '';
    return str.replace(/[-.]/g, '').toLowerCase();
};

export default function JobGlCaptureForm() {
    const navigate = useNavigate();
    const webcamRef = useRef(null);

    // ---------------------
    // Локальное состояние (ВЕСЬ draft)
    // ---------------------
    const [photo, setPhoto] = useState(null);
    const [isCameraOpen, setIsCameraOpen] = useState(false);

    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [employees, setEmployees] = useState([]);
    const [employeeModalOpen, setEmployeeModalOpen] = useState(false);

    // Левый блок
    const [jobList, setJobList] = useState([]);
    const [selectedJob, setSelectedJob] = useState(null);
    const [jobIdField, setJobIdField] = useState('');
    const [costCodes, setCostCodes] = useState([]);
    const [selectedCostCode, setSelectedCostCode] = useState(null);

    // Правый блок (GL)
    const [glCodes, setGlCodes] = useState([]);
    const [selectedGlCode, setSelectedGlCode] = useState(null);

    // Прочее
    const [description, setDescription] = useState('');
    const [isConfirmed, setIsConfirmed] = useState(false);
    const [submitMessage, setSubmitMessage] = useState('');

    // =====================
    // 1) Чтение из IndexedDB + восстановление
    // =====================
    useEffect(() => {
        (async function loadData() {
            try {
                const db = await initializeDB();

                const txEmp = db.transaction(EMPLOYEE_STORE, 'readonly');
                const empStore = txEmp.objectStore(EMPLOYEE_STORE);
                const allEmployees = await empStore.getAll();

                const txJob = db.transaction(JOB_STORE, 'readonly');
                const jobStore = txJob.objectStore(JOB_STORE);
                const allJobs = await jobStore.getAll();

                const txCg = db.transaction(CODE_GROUP_STORE, 'readonly');
                const cgStore = txCg.objectStore(CODE_GROUP_STORE);
                const codeGroup = await cgStore.get(1);
                const costCodes = codeGroup?.costCodes || [];
                const glCodes = codeGroup?.glCodes || [];

                const txDraft = db.transaction(CC_STORE, 'readonly');
                const ccStore = txDraft.objectStore(CC_STORE);
                const draftData = await ccStore.get(1);

                setEmployees(allEmployees);
                setJobList(allJobs);
                setCostCodes(costCodes);
                setGlCodes(glCodes);

                if (draftData) {
                    // *** Восстанавливаем всё из draftData
                    setSelectedEmployee(draftData.selectedEmployee || null);
                    setSelectedJob(draftData.selectedJob || null);
                    setJobIdField(draftData.jobIdField || '');
                    setSelectedCostCode(draftData.selectedCostCode || null);
                    setSelectedGlCode(draftData.selectedGlCode || null);
                    setDescription(draftData.description || '');
                    setPhoto(draftData.photo || null);
                    setIsConfirmed(draftData.isConfirmed || false);
                } else {
                    // *** Если нет черновика, берём из localStorage
                    const employeeIdFromStorage = localStorage.getItem('employeeId');
                    if (employeeIdFromStorage) {
                        const foundEmp = allEmployees.find(
                            (e) => String(e.employeeId) === employeeIdFromStorage
                        );
                        if (foundEmp) {
                            setSelectedEmployee(foundEmp);
                        }
                    }
                }
            } catch (err) {
                console.error('Error loading from DB:', err);
            }
        })();
    }, []);

    // =====================
    // 2) saveDraft: ВСЕГДА формируем полный объект
    //    и целиком перезаписываем record в IndexedDB
    // =====================
    async function saveDraft(partial = {}) {
        try {
            // *** Сформируем обновлённый "draft" на базе нынешнего лок. state
            //     + partial, который говорит, что изменилось
            const updated = {
                selectedEmployee,
                selectedJob,
                jobIdField,
                selectedCostCode,
                selectedGlCode,
                description,
                photo,
                isConfirmed,
                // id=1
                id: 1,
            };

            // *** Применяем partial (указываем, что изменилось)
            if ('selectedEmployee' in partial) updated.selectedEmployee = partial.selectedEmployee;
            if ('selectedJob' in partial) updated.selectedJob = partial.selectedJob;
            if ('jobIdField' in partial) updated.jobIdField = partial.jobIdField;
            if ('selectedCostCode' in partial) updated.selectedCostCode = partial.selectedCostCode;
            if ('selectedGlCode' in partial) updated.selectedGlCode = partial.selectedGlCode;
            if ('description' in partial) updated.description = partial.description;
            if ('photo' in partial) updated.photo = partial.photo;
            if ('isConfirmed' in partial) updated.isConfirmed = partial.isConfirmed;

            // *** Синхронно обнoвим локальные стейты
            setSelectedEmployee(updated.selectedEmployee);
            setSelectedJob(updated.selectedJob);
            setJobIdField(updated.jobIdField);
            setSelectedCostCode(updated.selectedCostCode);
            setSelectedGlCode(updated.selectedGlCode);
            setDescription(updated.description);
            setPhoto(updated.photo);
            setIsConfirmed(updated.isConfirmed);

            // *** Запишем в IndexedDB
            const db = await initializeDB();
            const tx = db.transaction(CC_STORE, 'readwrite');
            const store = tx.objectStore(CC_STORE);
            await store.put(updated);
        } catch (error) {
            console.error('Error saving draft:', error);
        }
    }

    // =====================
    // 3) Камера
    // =====================
    const handleOpenCamera = () => {
        setIsCameraOpen(true);
        saveDraft({ photo: null });
    };

    const handleCapturePhoto = () => {
        if (webcamRef.current) {
            const screenshot = webcamRef.current.getScreenshot();
            saveDraft({ photo: screenshot });
            setIsCameraOpen(false);
        }
    };

    const handleRetakePhoto = () => {
        saveDraft({ photo: null });
        setIsCameraOpen(true);
    };

    // =====================
    // 4) Модалка сотрудника
    // =====================
    const handleOpenEmployeeModal = () => setEmployeeModalOpen(true);
    const handleCloseEmployeeModal = () => setEmployeeModalOpen(false);
    const handleSelectEmployee = (emp) => {
        saveDraft({ selectedEmployee: emp });
    };

    // =====================
    // 5) Логика "взаимоисключающих" блоков
    // =====================
    const handleChangeJobId = (e) => {
        const newVal = e.target.value.slice(0, 5);
        // Левый блок => очищаем glCode
        saveDraft({
            jobIdField: newVal,
            selectedGlCode: null,
        });
    };

    const handleSelectJob = (event, newVal) => {
        // Левый блок => очищаем glCode
        saveDraft({
            selectedJob: newVal,
            selectedGlCode: null,
        });
    };

    const handleSelectCostCode = (event, newVal) => {
        // Левый блок => очищаем glCode
        saveDraft({
            selectedCostCode: newVal,
            selectedGlCode: null,
        });
    };

    const handleSelectGlCode = (event, newVal) => {
        // Правый блок => очищаем левый
        saveDraft({
            selectedGlCode: newVal,
            jobIdField: '',
            selectedJob: null,
            selectedCostCode: null,
        });
    };

    // Фильтры
    const filterJobs = (opts, { inputValue }) => {
        const n = normalizeSearch(inputValue);
        return opts.filter((item) => normalizeSearch(item.Job + ' ' + item.JobName).includes(n));
    };

    const filterCostCodes = (opts, { inputValue }) => {
        const n = normalizeSearch(inputValue);
        return opts.filter((item) =>
            normalizeSearch(item.CostCode + ' ' + item.Description).includes(n)
        );
    };

    const filterGlCodes = (opts, { inputValue }) => {
        const n = normalizeSearch(inputValue);
        return opts.filter((item) =>
            normalizeSearch(item.GLCode + ' ' + item.Description).includes(n)
        );
    };

    // =====================
    // 6) Submit
    // =====================
    const handleSubmit = async () => {
        try {
            const payload = {
                employeeId: selectedEmployee?.id || null,
                jobId: selectedJob?.id || null,
                jobIdField,
                costCodeId: selectedCostCode?.id || null,
                glCodeId: selectedGlCode?.id || null,
                description,
                photo,
                createdAt: new Date().toISOString(),
            };

            const resp = await axiosInstance.post('/my-endpoint-for-cost-gl', payload);
            if (resp.status === 200) {
                setSubmitMessage('Data submitted!');
                await clearFormAndDraft();
            } else {
                alert('Server did not accept data. Data will retry later.');
            }
        } catch (err) {
            console.error('Error submitting:', err);
            alert('Offline or error. Data saved locally and will resend later.');
        }
    };

    async function clearFormAndDraft() {
        setSelectedEmployee(null);
        setSelectedJob(null);
        setJobIdField('');
        setSelectedCostCode(null);
        setSelectedGlCode(null);
        setDescription('');
        setPhoto(null);
        setIsConfirmed(false);
        setSubmitMessage('');

        try {
            const db = await initializeDB();
            const tx = db.transaction(CC_STORE, 'readwrite');
            const store = tx.objectStore(CC_STORE);
            await store.delete(1);
        } catch (error) {
            console.error('Error clearing draft:', error);
        }
    }

    // =====================
    // 7) Логика активного блока + блокировка Submit
    // =====================
    const haveLeftBlock = (jobIdField || selectedJob) && selectedCostCode;
    const haveRightBlock = selectedGlCode;
    const isSubmitDisabled =
        !photo ||
        !selectedEmployee ||
        !isConfirmed ||
        (!haveLeftBlock && !haveRightBlock);

    // Подсветка
    const leftBlockActive = haveLeftBlock;
    const rightBlockActive = haveRightBlock;
    const getBlockStyle = (isActive) => ({
        border: isActive ? '2px solid #1976d2' : '1px dashed #ccc',
        borderRadius: 2,
        p: 2,
        transition: 'border 0.2s',
    });

    // =====================
    // RENDER
    // =====================
    return (
        <Box sx={{ p: 2 }}>
            <Box mb={2}>
                <Button
                    variant="contained"
                    color="success"
                    startIcon={<ArrowBackIcon />}
                    onClick={() => navigate(-1)}
                >
                    Back
                </Button>
            </Box>

            <Card>
                <CardContent>
                    <Typography variant="h6" gutterBottom>
                        Capture Photo and Fill Form
                    </Typography>

                    {/* FOTO */}
                    {photo ? (
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 2 }}>
                            <img
                                src={photo}
                                alt="Captured"
                                style={{
                                    maxWidth: '100%',
                                    borderRadius: 8,
                                    boxShadow: '0 0 10px rgba(0,0,0,0.2)',
                                    marginBottom: '8px',
                                }}
                            />
                            <Button
                                variant="outlined"
                                color="primary"
                                startIcon={<PhotoCamera />}
                                onClick={handleRetakePhoto}
                            >
                                Retake Photo
                            </Button>
                        </Box>
                    ) : (
                        <Box sx={{ textAlign: 'center', mb: 2 }}>
                            {isCameraOpen ? (
                                <Box sx={{ position: 'relative', maxWidth: 400, mx: 'auto' }}>
                                    <Webcam
                                        ref={webcamRef}
                                        screenshotFormat="image/jpeg"
                                        videoConstraints={{ facingMode: 'environment' }}
                                        style={{
                                            width: '100%',
                                            borderRadius: 12,
                                            boxShadow: '0 0 10px rgba(0,0,0,0.3)',
                                        }}
                                    />
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            width: '100%',
                                            height: '100%',
                                            borderRadius: 12,
                                            pointerEvents: 'none',
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                position: 'absolute',
                                                top: 0,
                                                left: 0,
                                                right: 0,
                                                bottom: 0,
                                                border: '3px solid #1976d2',
                                                borderRadius: 12,
                                            }}
                                        />
                                        <Box
                                            sx={{
                                                position: 'absolute',
                                                top: 16,
                                                left: 16,
                                                width: 42,
                                                height: 30,
                                                borderRadius: 4,
                                                backgroundColor: 'rgba(255,255,255,0.5)',
                                                border: '1px solid #fff',
                                            }}
                                        />
                                        <Typography
                                            sx={{
                                                position: 'absolute',
                                                bottom: 16,
                                                width: '100%',
                                                textAlign: 'center',
                                                color: '#fff',
                                                fontSize: 16,
                                                letterSpacing: '2px',
                                                fontWeight: 'bold',
                                                textShadow: '0 0 4px rgba(0,0,0,0.7)',
                                                userSelect: 'none',
                                            }}
                                        >
                                            0000 0000 0000 0000
                                        </Typography>
                                    </Box>
                                </Box>
                            ) : (
                                <Typography variant="body1" sx={{ mb: 1 }}>
                                    No photo yet
                                </Typography>
                            )}

                            {isCameraOpen ? (
                                <Box sx={{ mt: 2 }}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        startIcon={<PhotoCamera />}
                                        onClick={handleCapturePhoto}
                                    >
                                        Take Photo
                                    </Button>
                                </Box>
                            ) : (
                                <Box sx={{ mt: 2 }}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        startIcon={<PhotoCamera />}
                                        onClick={handleOpenCamera}
                                    >
                                        Open Camera
                                    </Button>
                                </Box>
                            )}
                        </Box>
                    )}

                    {/* Сотрудник */}
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                        <TextField
                            label="Employee"
                            variant="outlined"
                            value={selectedEmployee?.FullName || ''}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <PersonIcon />
                                    </InputAdornment>
                                ),
                                readOnly: true,
                            }}
                            sx={{ flex: 1 }}
                            onClick={handleOpenEmployeeModal}
                        />
                    </Box>

                    <Divider sx={{ my: 2 }} />

                    <Grid container spacing={2}>
                        {/* Левый блок */}
                        <Grid item xs={12} md={5}>
                            <Paper sx={getBlockStyle(leftBlockActive)}>
                                {/* Job ID */}
                                <TextField
                                    label="Job ID"
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    inputProps={{ maxLength: 5 }}
                                    value={jobIdField}
                                    onChange={handleChangeJobId}
                                />

                                {/* Select Job */}
                                <Autocomplete
                                    options={jobList}
                                    getOptionLabel={(item) => item.Job ? (item.Job + ' ' + (item.JobName||'')) : ''}
                                    filterOptions={filterJobs}
                                    value={selectedJob}
                                    onChange={handleSelectJob}
                                    isOptionEqualToValue={(option, value) => option.id === value?.id}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Select Job"
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                        />
                                    )}
                                />

                                {/* Select CostCode */}
                                <Autocomplete
                                    options={costCodes}
                                    getOptionLabel={(item) =>
                                        item.CostCode ? (item.CostCode + ' ' + (item.Description||'')) : ''
                                    }
                                    filterOptions={filterCostCodes}
                                    value={selectedCostCode}
                                    onChange={handleSelectCostCode}
                                    isOptionEqualToValue={(option, value) => option.id === value?.id}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Select Cost Code"
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                        />
                                    )}
                                />
                            </Paper>
                        </Grid>

                        {/* OR */}
                        <Grid item xs={12} md={2}>
                            <Box
                                sx={{
                                    height: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <Typography variant="h6" color="text.secondary">
                                    OR
                                </Typography>
                            </Box>
                        </Grid>

                        {/* Правый блок (GL) */}
                        <Grid item xs={12} md={5}>
                            <Paper sx={getBlockStyle(rightBlockActive)}>
                                <Autocomplete
                                    options={glCodes}
                                    getOptionLabel={(item) =>
                                        item.GLCode ? (item.GLCode + ' ' + (item.Description||'')) : ''
                                    }
                                    filterOptions={filterGlCodes}
                                    value={selectedGlCode}
                                    onChange={handleSelectGlCode}
                                    isOptionEqualToValue={(option, value) => option.id === value?.id}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Select GL Code"
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                        />
                                    )}
                                />
                            </Paper>
                        </Grid>
                    </Grid>

                    {/* Description */}
                    <TextField
                        label="Description (optional)"
                        variant="outlined"
                        multiline
                        rows={3}
                        fullWidth
                        value={description}
                        onChange={(e) => saveDraft({ description: e.target.value })}
                        sx={{ mt: 2 }}
                    />

                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={isConfirmed}
                                onChange={(e) => saveDraft({ isConfirmed: e.target.checked })}
                            />
                        }
                        label="I confirm that the image is visible and the coding is correct"
                        sx={{ mt: 2 }}
                    />

                    {/* Submit */}
                    <Button
                        variant="contained"
                        color="success"
                        sx={{ mt: 2 }}
                        onClick={handleSubmit}
                        disabled={
                            !photo ||
                            !selectedEmployee ||
                            !isConfirmed ||
                            (!((jobIdField || selectedJob) && selectedCostCode) && !selectedGlCode)
                        }
                    >
                        Submit
                    </Button>

                    {submitMessage && (
                        <Typography variant="body1" color="green" sx={{ mt: 2 }}>
                            {submitMessage}
                        </Typography>
                    )}
                </CardContent>
            </Card>

            {/* Модалка сотрудника */}
            <SelectEmployeeModal
                open={employeeModalOpen}
                onClose={handleCloseEmployeeModal}
                employees={employees}
                onSelect={handleSelectEmployee}
                title="Select Employee"
            />
        </Box>
    );
}
