import React from 'react';
import { Button, Typography } from '@mui/material';
import { formatSyncTime } from '../../utils/formatSyncTime';

const SyncActions = ({ overallSyncTime, loadingEmployees, loadingInstructions, loadingTrainingDue, loadingCostCode, loadingGLCode, loadingCodeGroup, onSyncAll }) => {

    const isSyncingAll = loadingEmployees || loadingInstructions || loadingTrainingDue || loadingCostCode || loadingGLCode || loadingCodeGroup;

    return (
        <>
            <Typography variant="body1" sx={{ mb: 2, color: 'gray' }}>
                Last overall synchronization: <strong>{formatSyncTime(overallSyncTime)}</strong>
            </Typography>
            <Button
                variant="contained"
                color="primary"
                onClick={onSyncAll}
                disabled={isSyncingAll}
                sx={{ mb: 3 }}
            >
                {isSyncingAll ? 'Syncing All...' : 'Sync All Data'}
            </Button>
        </>
    );
};

export default SyncActions;
