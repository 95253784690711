// src/components/tbt/TBTInstructionTable.jsx
import React, { useState, useEffect } from 'react';
import { Box, Paper } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { getBaseColumns } from '../../utils/dataGridColumns';

const TBTInstructionTable = ({ weeklyInstructions, onViewDetail }) => {
    const [isNarrow, setIsNarrow] = useState(false);

    const safeData = Array.isArray(weeklyInstructions)
        ? weeklyInstructions.filter(item => item && item.uniqueId)
        : [];

    useEffect(() => {
        const checkWidth = () => {
            setIsNarrow(window.innerWidth < 900);
        };
        window.addEventListener('resize', checkWidth);
        checkWidth();
        return () => {
            window.removeEventListener('resize', checkWidth);
        };
    }, []);

    const baseColumns = getBaseColumns();
    // Если экран узкий, убираем колонку signedEmployees из массива
    const columns = isNarrow
        ? baseColumns.filter(col => col.field !== 'signedEmployees')
        : baseColumns;

    return (
        <Paper elevation={3} sx={{ p: 2, width: '100%' }}>
            <Box sx={{ width: '100%' }}>
                <DataGrid
                    rows={safeData}
                    columns={columns}
                    autoHeight
                    pageSize={5}
                    rowsPerPageOptions={[5, 10, 50, 100]}
                    disableSelectionOnClick
                    getRowId={(row) => row.uniqueId}
                    onRowClick={(params) => {
                        if (params?.row?.uniqueId) {
                            onViewDetail(params.row.uniqueId);
                        }
                    }}
                    rowHeight={85} // Высота строк 85px
                    sx={{
                        '& .MuiDataGrid-columnHeaders': {
                            backgroundColor: '#f5f5f5',
                            borderBottom: '1px solid #ccc',
                        },
                        '& .MuiDataGrid-cell': {
                            borderBottom: '1px solid #eee',
                            display: 'flex',
                            alignItems: 'center',
                            p: 1,
                            lineHeight: '1.3',
                            whiteSpace: 'normal',
                            wordBreak: 'break-word',
                        },
                        '& .MuiDataGrid-row:hover': {
                            cursor: 'pointer',
                            backgroundColor: '#fafafa',
                        },
                    }}
                />
            </Box>
        </Paper>
    );
};

export default TBTInstructionTable;
