import { useEffect, useState } from 'react';
import { initializeDB } from '../config/db';
import { INSTRUCTION_STORE, WEEKLY_INSTRUCTION_STORE } from '../config/constants';

export function useWeeklyInstructions() {
    const [weeklyInstructions, setWeeklyInstructions] = useState([]);

    const fetchWeeklyInstructions = async () => {
        try {
            const db = await initializeDB();

            // Получаем все инструкции
            const instructionTx = db.transaction(INSTRUCTION_STORE, 'readonly');
            const instructionStore = instructionTx.objectStore(INSTRUCTION_STORE);
            const allInstructions = await instructionStore.getAll();

            const instructionMap = {};
            allInstructions.forEach(instruction => {
                instructionMap[instruction.id] = instruction;
            });

            // Получаем все недельные инструкции
            const weeklyInstructionTx = db.transaction(WEEKLY_INSTRUCTION_STORE, 'readonly');
            const weeklyInstructionStore = weeklyInstructionTx.objectStore(WEEKLY_INSTRUCTION_STORE);
            const allWeeklyInstructions = await weeklyInstructionStore.getAll();

            // Фильтр
            const filteredInstructions = allWeeklyInstructions.filter(weeklyInstruction => {
                const instruction = instructionMap[weeklyInstruction.instructionId];
                return !(weeklyInstruction.status === 'sent' && instruction?.status === 'passive');
            });

            setWeeklyInstructions(filteredInstructions);
        } catch (error) {
            console.error('Ошибка при получении данных из IDB:', error);
        }
    };

    useEffect(() => {
        fetchWeeklyInstructions();

        const handleSyncComplete = () => {
            fetchWeeklyInstructions();
        };

        window.addEventListener('syncComplete', handleSyncComplete);
        return () => {
            window.removeEventListener('syncComplete', handleSyncComplete);
        };
    }, []);

    return weeklyInstructions;
}
