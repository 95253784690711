import { useState, useEffect } from 'react';
import { formatSyncTime } from '../utils/formatSyncTime';

export const useSyncTime = () => {
    const [lastSyncTime, setLastSyncTime] = useState(
        formatSyncTime(localStorage.getItem('lastOverallSyncTime') || 'Never')
    );

    useEffect(() => {
        const handleSyncComplete = (event) => {
            setLastSyncTime(formatSyncTime(event.detail));
        };

        window.addEventListener('syncComplete', handleSyncComplete);
        return () => {
            window.removeEventListener('syncComplete', handleSyncComplete);
        };
    }, []);

    return lastSyncTime;
};
