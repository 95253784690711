// src/components/training/TrainingPage.jsx
import React from 'react';
import { Container, Grid, Card, CardContent, Typography, Icon, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import ChecklistIcon from '@mui/icons-material/Checklist';
import TimelapseIcon from '@mui/icons-material/Timelapse';

const options = [
    { name: 'MEWP EQUIPMENT PROFICIENCY', icon: <ChecklistIcon fontSize="large" />, link: '/#/training/mep' },
    { name: 'FORKLIFT TRAINING', icon: <ChecklistIcon fontSize="large" />, link: '/#/training/ft' },
    { name: 'Training Due', icon: <TimelapseIcon fontSize="large" />, link: '/#/training-due' }
];

export const TrainingPage = () => {
    return (
        <Container maxWidth={false} style={{ padding: '20px', width: '100%' }}>

            {/* Кнопка "Back" — добавляем, как в TBTPage */}
            <Link to="/">
                <Button
                    variant="contained"
                    color="success"
                    sx={{ mb: 2 }}
                >
                    Back
                </Button>
            </Link>

            <Grid container spacing={4} justifyContent="center" alignItems="center">
                {options.map((option, index) => (
                    <Grid item xs={12} sm={6} md={3} key={index}>
                        <a href={option.link} style={{ textDecoration: 'none' }}>
                            <Card
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    height: '200px',
                                    backgroundColor: '#f0f4f8',
                                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                    borderRadius: '12px',
                                    transition: 'transform 0.3s',
                                    '&:hover': {
                                        transform: 'scale(1.05)',
                                        cursor: 'pointer',
                                    },
                                }}
                            >
                                <CardContent sx={{ textAlign: 'center' }}>
                                    <Icon sx={{ fontSize: 50, color: '#1976d2' }}>
                                        {option.icon}
                                    </Icon>
                                    <Typography variant="h6" sx={{ marginTop: '10px', color: '#000' }}>
                                        {option.name}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </a>
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
};
