// components/tbt/TextModal.jsx
import React from 'react';
import { Box, Typography, Modal, Button } from '@mui/material';

const TextModal = ({ open, onClose, textContent }) => {
    return (
        <Modal open={open} onClose={onClose}>
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '600px',
                maxHeight: '80vh',
                overflowY: 'auto',
                bgcolor: 'background.paper',
                p: 3,
                boxShadow: 24,
                borderRadius: '8px',
            }}>
                <Typography variant="h6">Instruction Text</Typography>
                <Typography variant="body1" sx={{ mt: 2, whiteSpace: 'pre-line' }}>{textContent}</Typography>
                <Button onClick={onClose} variant="contained" sx={{ mt: 2 }}>Close</Button>
            </Box>
        </Modal>
    );
};

export default TextModal;
