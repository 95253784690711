// src/utils/dataGridColumns.js
import { Typography, Tooltip } from '@mui/material';
import React from 'react';

function extractDateString(dateValue) {
    if (dateValue && typeof dateValue === 'object' && dateValue.date) {
        return dateValue.date;
    }
    return dateValue;
}

function formatDate(value) {
    if (!value) return 'N/A';
    const parsedDate = new Date(value);
    return isNaN(parsedDate.getTime())
        ? value
        : parsedDate.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' });
}

function formatDateTime(value) {
    if (!value) return 'N/A';
    const parsedDate = new Date(value);
    return isNaN(parsedDate.getTime())
        ? value
        : parsedDate.toLocaleString('en-US', {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
        });
}

// Просто показываем весь текст без обрезаний.
// Используем перенос слов, чтобы текст показывался полностью.
function renderFullText(value) {
    const displayValue = value || 'N/A';
    return (
        <Tooltip title={displayValue}>
            <Typography
                sx={{
                    whiteSpace: 'normal',
                    wordBreak: 'break-word',
                    maxWidth: '100%'
                }}
            >
                {displayValue}
            </Typography>
        </Tooltip>
    );
}

// Формируем базовый массив колонок.
export const getBaseColumns = () => [
    {
        field: 'TBTNo',
        headerName: 'TBT No',
        flex: 0.5,
        minWidth: 100,
        headerAlign: 'left',
        align: 'left',
        renderCell: (params) => renderFullText(params.row?.TBTNo),
    },
    {
        field: 'title',
        headerName: 'Title',
        flex: 2,
        minWidth: 200,
        headerAlign: 'left',
        align: 'left',
        renderCell: (params) => renderFullText(params.row?.title),
    },
    {
        field: 'date',
        headerName: 'Date',
        flex: 1,
        minWidth: 120,
        headerAlign: 'left',
        align: 'left',
        renderCell: (params) => {
            const raw = extractDateString(params.row?.date);
            const val = formatDate(raw);
            return renderFullText(val);
        },
    },
    {
        field: 'signedEmployees',
        headerName: 'Signed/Unsigned',
        flex: 1,
        minWidth: 100,
        headerAlign: 'left',
        align: 'left',
        renderCell: (params) => {
            const status = params.row?.status;
            const employees = params.row?.signedEmployees || [];
            if (status === 'new') {
                return renderFullText('—');
            }
            const signedCount = employees.filter(emp => emp.signature !== null).length;
            return renderFullText(`${signedCount} / ${employees.length}`);
        },
    },
    {
        field: 'lastSentAt',
        headerName: 'Last Sent',
        flex: 1,
        minWidth: 150,
        headerAlign: 'left',
        align: 'left',
        renderCell: (params) => {
            const status = params.row?.status;
            const raw = extractDateString(params.row?.lastSentAt);
            if (status === 'new') return renderFullText('—');
            const val = formatDateTime(raw);
            return renderFullText(val);
        },
    },
    {
        field: 'status',
        headerName: 'Status',
        flex: 0.5,
        minWidth: 80,
        headerAlign: 'left',
        align: 'left',
        renderCell: (params) => {
            const status = params.row?.status || 'N/A';
            const color = status === 'new' ? 'red' : (status === 'sent' ? 'green' : 'inherit');
            const displayValue = status.charAt(0).toUpperCase() + status.slice(1);
            return (
                <Tooltip title={displayValue}>
                    <Typography
                        sx={{
                            whiteSpace: 'normal',
                            wordBreak: 'break-word',
                            maxWidth: '100%',
                            color
                        }}
                    >
                        {displayValue}
                    </Typography>
                </Tooltip>
            );
        },
    },
];
