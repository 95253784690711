import React, { useState, useEffect } from 'react';
import { Box, Modal, Button, Typography, Checkbox } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { initializeDB } from '../../config/db';
import { EMPLOYEE_STORE, DB_VERSION } from '../../config/constants';

const EmployeeSelectionModal = ({ open, onClose, onSave, existingEmployees }) => {
    const [employees, setEmployees] = useState([]);
    const [selectedEmployees, setSelectedEmployees] = useState(existingEmployees);

    useEffect(() => {
        const loadEmployees = async () => {
            const db = await initializeDB();
            const tx = db.transaction(EMPLOYEE_STORE, 'readonly');
            const store = tx.objectStore(EMPLOYEE_STORE);
            const allEmployees = await store.getAll();
            setEmployees(allEmployees);
        };

        if (open) {
            loadEmployees();
        }
    }, [open]);

    useEffect(() => {
        setSelectedEmployees(existingEmployees);
    }, [existingEmployees]);

    const handleCheckboxChange = (id) => {
        setSelectedEmployees((prevSelected) =>
            prevSelected.includes(id)
                ? prevSelected.filter((empId) => empId !== id)
                : [...prevSelected, id]
        );
    };

    const handleSave = () => {
        onSave(selectedEmployees);
        onClose();
    };

    const columns = [
        {
            field: 'select',
            headerName: '',
            width: 50,
            renderCell: (params) => (
                <Checkbox
                    checked={selectedEmployees.includes(params.row.id)}
                    onChange={() => handleCheckboxChange(params.row.id)}
                />
            ),
        },
        { field: 'Division', headerName: 'Division', width: 150 },
        { field: 'FullName', headerName: 'Full Name', flex: 1 },
    ];

    return (
        <Modal open={open} onClose={onClose}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '600px',
                    maxHeight: '90vh',
                    overflowY: 'auto',
                    bgcolor: 'background.paper',
                    p: 3,
                    boxShadow: 24,
                    borderRadius: '8px',
                }}
            >
                <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
                    <Typography variant="h6" gutterBottom>
                        Select Employees for TBT Task
                    </Typography>

                    <Button variant="contained" color="primary" onClick={handleSave}>
                        Save
                    </Button>
                </Box>
                <Box sx={{ height: '80vh', width: '100%', mb: 2 }}>
                    <DataGrid
                        rows={employees}
                        columns={columns}
                        pageSize={5}
                        rowsPerPageOptions={[5]}
                        disableSelectionOnClick
                    />
                </Box>

            </Box>
        </Modal>
    );
};

export default EmployeeSelectionModal;
