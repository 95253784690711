// src/components/trainingDue/TraininDue.jsx

import React, {useEffect, useState} from 'react';
import {Box, Button, Paper, Typography} from '@mui/material';
import {DataGrid} from '@mui/x-data-grid';
import {Link} from "react-router-dom";

import {useTrainingDue} from '../../../hooks/useTrainingDue';
import {formatUSDateNoTime} from "../../../utils/utils";

export default function TrainingDue() {
    const trainingDueList = useTrainingDue();
    const [isNarrow, setIsNarrow] = useState(false);

    useEffect(() => {
        const checkWidth = () => {
            setIsNarrow(window.innerWidth < 900);
        };
        window.addEventListener('resize', checkWidth);
        checkWidth();
        return () => window.removeEventListener('resize', checkWidth);
    }, []);

    const safeData = Array.isArray(trainingDueList)
        ? trainingDueList.filter(item => item && item.id != null)
        : [];

    const baseColumns = [
        { field: 'fullName', headerName: 'Employee name', flex: 1 },
        { field: 'division', headerName: 'Division', flex: 1 },
        { field: 'course', headerName: 'Training Course', flex: 2 },
        {
            field: 'dateDue',
            headerName: 'Training Due',
            flex: 1,
            renderCell: (params) => {
                return formatUSDateNoTime(params.value);
            },
        },
    ];

    const columns = isNarrow ? baseColumns : baseColumns;

    return (
        <Box sx={{ p: 4 }}>
            <Link to="/training">
                <Button
                    variant="contained"
                    color="success"
                    sx={{ mb: 2 }}
                >
                    Back
                </Button>
            </Link>

            <Typography variant="h4" gutterBottom>
                Training Due
            </Typography>

            <Paper elevation={3} sx={{ p: 2, width: '100%' }}>
                <Box sx={{ width: '100%' }}>
                    <DataGrid
                        rows={safeData}
                        columns={columns}

                        // Убираем pageSize, а используем initialState + pageSizeOptions
                        initialState={{
                            pagination: {
                                paginationModel: { pageSize: 50 } // стартуем с 50
                            }
                        }}
                        pageSizeOptions={[5, 10, 50, 100]} // варианты переключения

                        disableSelectionOnClick
                        getRowId={(row) => row.id}
                        rowHeight={85}
                        sx={{
                            '& .MuiDataGrid-columnHeaders': {
                                backgroundColor: '#f5f5f5',
                                borderBottom: '1px solid #ccc',
                            },
                            '& .MuiDataGrid-cell': {
                                borderBottom: '1px solid #eee',
                                display: 'flex',
                                alignItems: 'center',
                                p: 1,
                                lineHeight: '1.3',
                                whiteSpace: 'normal',
                                wordBreak: 'break-word',
                            },
                            '& .MuiDataGrid-row:hover': {
                                cursor: 'pointer',
                                backgroundColor: '#fafafa',
                            },
                        }}
                    />
                </Box>
            </Paper>
        </Box>
    );
}
