import {TbtStatusInfo} from "./TbtStatusInfo";
import {CcStatusInfo} from "./CcStatusInfo";
import {Card, CardContent, Grid, Icon, Typography} from "@mui/material";
import React from "react";

export function CardOption({ name, icon, link, tbtStatuses, ccStatus }) {

    let extraInfo = null;

    if (name === 'TBT') {
        extraInfo = <TbtStatusInfo statuses={tbtStatuses} />;
    } else if (name === 'CC') {
        extraInfo = <CcStatusInfo status={ccStatus} />;
    }

    return (
        <Grid item xs={12} sm={6} md={3}>
            <a href={link} style={{ textDecoration: 'none' }}>
                <Card
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '200px',
                        backgroundColor: '#f0f4f8',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                        borderRadius: '12px',
                        transition: 'transform 0.3s',
                        '&:hover': {
                            transform: 'scale(1.05)',
                            cursor: 'pointer',
                        },
                    }}
                >
                    <CardContent sx={{ textAlign: 'center' }}>
                        {/* Иконка */}
                        <Icon sx={{ fontSize: 50, color: '#1976d2' }}>{icon}</Icon>

                        {/* Название */}
                        <Typography variant="h6" sx={{ mt: 1, color: '#000' }}>
                            {name}
                        </Typography>

                        {/* Доп. инфо (статусы) */}
                        {extraInfo}
                    </CardContent>
                </Card>
            </a>
        </Grid>
    );
}