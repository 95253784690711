import AssignmentIcon from "@mui/icons-material/Assignment";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import React from "react";

export const MAIN_MENU_ITEMS = [
    {
        label: 'Main',
        icon: <AssignmentIcon fontSize="small" />,
        link: '/main',
    },
    {
        label: 'Administration',
        icon: <AdminPanelSettingsIcon fontSize="small" />,
        subMenu: [
            {
                label: 'Synchronization',
                link: '/admin/synchronization',
            },
            {
                label: 'Weekly Roster',
                link: '/admin/weekly-employee',
            },
        ],
    },
    {
        label: 'TBT',
        icon: <WorkOutlineIcon fontSize="small" />,
        subMenu: [
            { label: 'TBT', link: '/tbt' },
            { label: 'Create new TBT', link: '/tbt/add' },
        ],
    },
    {
        label: 'JSA',
        icon: <WorkOutlineIcon fontSize="small" />,
        subMenu: [
            { label: 'Create New Form', link: '/jsa/new' },
            { label: 'Saved Forms', link: '/jsa/saved' },
            { label: 'Submitted Forms', link: '/jsa/submitted' },
        ],
    },
    {
        label: 'CC',
        icon: <AssignmentIcon fontSize="small" />,
        link: '/cc',
    },
    {
        label: 'PO',
        icon: <AssignmentIcon fontSize="small" />,
        link: '/po',
    },
];