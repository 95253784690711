import DocumentIcon from "@mui/icons-material/Description";

import CreditCard from "@mui/icons-material/CreditCard";
import NotesIcon from "@mui/icons-material/Assignment";
import WorkIcon from "@mui/icons-material/Work";
import ModelTrainingIcon from "@mui/icons-material/ModelTraining";
import React from "react";

export const option = [
    {
        name: 'TBT',
        icon: <DocumentIcon fontSize="large" />,
        link: '/#/tbt',
    },
    {
        name: 'CC',
        icon: <CreditCard fontSize="large" />,
        link: '/#/cc',
    },
    {
        name: 'PO',
        icon: <NotesIcon fontSize="large" />,
        link: '/#/po',
    },
    {
        name: 'JSA',
        icon: <WorkIcon fontSize="large" />,
        link: '/#/jsa',
    },
    {
        name: 'Training',
        icon: <ModelTrainingIcon fontSize="large" />,
        link: '/#/training',
    },
];