// SWUpdatePrompt.js
import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';

function SWUpdatePrompt({ open, onClose, waitingWorker, updateApp }) {
    const currentVersion = process.env.REACT_APP_VERSION;
    if (!waitingWorker) {
        // Если нет обновления, окно можно не отображать
        return null;
    }

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>New Version Available</DialogTitle>
            <DialogContent>
                <Typography>
                    A new version of the app is available. Current version: v{currentVersion}.
                </Typography>
                <Typography>
                    Would you like to update now?
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Later</Button>
                <Button onClick={updateApp} variant="contained" color="primary">
                    Update
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default SWUpdatePrompt;
