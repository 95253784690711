// src/training/evaluation/MepForm.jsx
import React, { useEffect, useState, useCallback } from 'react';
import { Box, Button, Typography } from '@mui/material';

import TrainingFormHeader from './../TrainingFormHeader';
import TrainingFormSection from './../TrainingFormSection';

import {
    saveMepForm,
    markMepFormSend, getAllForms
} from '../../../services/mepFormService';

import {
    TRAINING_FORM_STATUS_FINISH,
    TRAINING_FORM_STATUS_PROCESS,
    TRAINING_FORM_STATUS_SEND
} from '../../../config/constants';

import SignatureModal from "../SignatureModal";
import axiosInstance from "../../../axiosInstance";

export default function MepForm() {
    const defaultFormData = {
        operatorName: '',
        trainerName: '',
        date: new Date().toISOString().split('T')[0],
        section7: {
            raiseAndLower: false,
            travelForward: false,
            rotateLeftRight: false,
            moveBoomInOut: false,
            rotateBasket: false,
            useArticulatingControls: false,
            useOutriggers: false,
            otherEnabled: false,
            other: '',
        },
        section8: {
            nearPowerLines: false,
            fallTipHazards: false,
            controlNotInView: false,
            emergencyOperation: false,
            maxSideForce: false,
        },
        section9: {
            properMounting: false,
            directionOfTravel: false,
            weightCapacity: false,
            appropriateSpeeds: false,
            unlevelGround: false,
            properSurface:false,
            cornering:false,
            SoundingIntersections:false,
        },
        section10: {
            properParking: false,
            hazardousAreas: false,
            awareOfEmployees: false,
            otherEnabled: false,
            other: ''
        },
        section11:{
            success: false,
        },
        completed: false,
        signature: null,
        status: TRAINING_FORM_STATUS_PROCESS,
    };

    const [formData, setFormData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [showSignatureModal, setShowSignatureModal] = useState(false);

    useEffect(() => {
        (async () => {
            try {
                // Получаем ВСЕ формы из IndexedDB (store: mepForm)
                const allForms = await getAllForms();
                // Фильтруем, убираем те, где status === SEND
                const activeForms = allForms.filter(f => f.status !== TRAINING_FORM_STATUS_SEND);

                if (activeForms.length > 0) {
                    // Возьмём последнюю (например, по id – самый большой)
                    // Ниже сортируем по id, и берём “самую свежую”
                    activeForms.sort((a, b) => (b.id || 0) - (a.id || 0));
                    const latestForm = activeForms[0];
                    setFormData(latestForm);
                } else {
                    // Нет forms со статусом process/finish => новая форма
                    setFormData(defaultFormData);
                }
            } catch (err) {
                console.error('Error loading MEP forms:', err);
                // Если ошибка => пустая форма
                setFormData(defaultFormData);
            } finally {
                setLoading(false);
            }
        })();
    }, []);

    // Автосохранение
    const autoSaveForm = useCallback(async (updatedForm) => {
        try {
            const newId = await saveMepForm(updatedForm);
            setFormData(prev => (prev ? { ...prev, id: newId } : prev));
        } catch (error) {
            console.error('Auto-save error:', error);
        }
    }, []);

    // Если статус не FINISH => ставим PROCESS (не трогаем, если уже FINISH)
    const handleUpdateFormData = (updated) => {
        if (updated.status !== TRAINING_FORM_STATUS_FINISH) {
            updated.status = TRAINING_FORM_STATUS_PROCESS;
        }
        setFormData(updated);
        autoSaveForm(updated);
    };

    const handleInputChange = (key, value) => {
        if (!formData) return;
        const newData = { ...formData, [key]: value };
        handleUpdateFormData(newData);
    };

    const handleFieldChange = (section, key, value) => {
        if (!formData) return;
        const newSectionData = { ...formData[section], [key]: value };
        if (key === 'otherEnabled' && value === false) {
            newSectionData.other = '';
        }
        const newData = { ...formData, [section]: newSectionData };
        handleUpdateFormData(newData);
    };

    // Отправить => status = SEND, убираем из локального стейта => новая форма
    const handleSend = async () => {
        if (!formData) return;

        // 1) Проверяем обязательные поля
        if (!formData.operatorName) {
            alert("Please select an operator name before sending.");
            return;
        }
        if (!formData.trainerName) {
            alert("Please select a trainer name before sending.");
            return;
        }
        if (!formData.section11.success) {
            alert("The maneuvering practice drills have not been successfully completed.");
            return;
        }
        if (!formData.signature) {
            alert("Signature is required to proceed.");
            return;
        }

        try {
            // 1) отправляем форму на сервер через axiosInstance

            const dataToSend = {
                ...formData,
                formType: 'mep',
            };

            // Отправляем POST-запрос на /training/form/save
            await axiosInstance.post('/training/form/save', dataToSend);

            // 2) Далее, если у формы есть id, помечаем её как SEND в IndexedDB
            if (formData.id) {
                await markMepFormSend(formData.id);
            }
            // 3) Очистка локального стейта => новая форма
            setFormData({ ...defaultFormData });
            alert('Data sent successfully. Starting a new form...');
        } catch (err) {
            console.error(err);
            alert('Error sending form data to server.');
        }
    };

    // Подпись
    const handleSignatureSaved = (base64Signature) => {
        if (!formData) return;
        let newStatus = TRAINING_FORM_STATUS_PROCESS;
        if (base64Signature) {
            newStatus = TRAINING_FORM_STATUS_FINISH;
        }
        const newData = {
            ...formData,
            signature: base64Signature || null,
            status: newStatus,
        };
        setFormData(newData);
        autoSaveForm(newData);
        setShowSignatureModal(false);
    };

    if (loading || !formData) {
        return <Typography>Loading...</Typography>;
    }

    return (
        <Box sx={{ p: 4 }}>
            <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2 }}>
                MEWP EQUIPMENT PROFICIENCY / EVALUATION FORM
            </Typography>

            {/* Шапка формы */}
            <TrainingFormHeader
                onGoBack={'#/training'}
                operatorName={formData.operatorName}
                trainerName={formData.trainerName}
                date={formData.date}
                onInputChange={handleInputChange}
            />

            {/* Section 7 */}
            <TrainingFormSection
                title="Ability to demonstrate and explain proper use of controls:"
                fields={[
                    { key: 'raiseAndLower', label: 'Raise & Lower', type: 'checkbox' },
                    { key: 'travelForward', label: 'Travel forward & Backwards while steering', type: 'checkbox' },
                    { key: 'rotateLeftRight', label: 'Rotate left & right', type: 'checkbox' },
                    { key: 'moveBoomInOut', label: 'Move boom in & out', type: 'checkbox' },
                    { key: 'rotateBasket', label: 'Rotate Basket', type: 'checkbox' },
                    { key: 'useArticulatingControls', label: 'Use Articulating Controls', type: 'checkbox' },
                    { key: 'useOutriggers', label: 'Use Outriggers if equipped', type: 'checkbox' },
                    { key: 'otherEnabled', label: 'Other', type: 'checkbox' },
                    { key: 'other', label: 'Other', type: 'text', conditionalOn: 'otherEnabled' },
                ]}
                values={formData.section7}
                onFieldChange={(key, value) => handleFieldChange('section7', key, value)}
            />


            {/* Section 8 */}
            <TrainingFormSection
                title=''
                fields={[
                    { key: 'nearPowerLines', label: 'Proper positioning of equipment when within vicinity of power lines', type: 'checkboxLeft' },
                ]}
                values={formData.section8}
                onFieldChange={(key, value) => handleFieldChange('section8', key, value)}
            />


            {/* Section 8 */}
            <TrainingFormSection
                title=""
                fields={[
                    { key: 'fallTipHazards', label: ' Proper positioning of equipment when near fall/tip over hazards', type: 'checkboxLeft' },
                ]}
                values={formData.section8}
                onFieldChange={(key, value) => handleFieldChange('section8', key, value)}
            />


            {/* Section 8 */}
            <TrainingFormSection
                title=""
                fields={[
                    { key: 'controlNotInView', label: 'Proper position of control when not in view - regardless of distance', type: 'checkboxLeft' },

                ]}
                values={formData.section8}
                onFieldChange={(key, value) => handleFieldChange('section8', key, value)}
            />


            {/* Section 8 */}
            <TrainingFormSection
                title=""
                fields={[
                    { key: 'emergencyOperation', label: 'Emergency operation to lower equipment due to power loss', type: 'checkboxLeft' }
                ]}
                values={formData.section8}
                onFieldChange={(key, value) => handleFieldChange('section8', key, value)}
            />


            {/* Section 8 */}
            <TrainingFormSection
                title=""
                fields={[
                    { key: 'maxSideForce', label: 'Understanding of the maximum allowable side force (horizontal load)', type: 'checkboxLeft' }
                ]}
                values={formData.section8}
                onFieldChange={(key, value) => handleFieldChange('section8', key, value)}
            />




            {/* Section 9 */}
            <TrainingFormSection
                title="Demonstration of proper equipment operation:"
                fields={[
                    { key: 'properMounting', label: 'Proper mounting and dismounting', type: 'checkbox' },
                    { key: 'directionOfTravel', label: 'Looking in the direction of travel', type: 'checkbox' },
                    { key: 'weightCapacity', label: 'Weight and personnel capacity', type: 'checkbox' },
                    { key: 'appropriateSpeeds', label: 'Appropriate speeds', type: 'checkbox' },
                    { key: 'unlevelGround', label: 'Un-level Ground & Speed bumps', type: 'checkbox' },
                    { key: 'properSurface', label: 'Proper adjustment to wet or uneven driving surface', type: 'checkbox' },
                    { key: 'cornering', label: 'Cornering', type: 'checkbox' },
                    { key: 'soundingIntersections', label: 'Sounding horn at corners and intersections', type: 'checkbox' },

                ]}
                values={formData.section9}
                onFieldChange={(key, value) => handleFieldChange('section9', key, value)}
            />
            {/* Section 10 */}
            <TrainingFormSection
                title="Workplace conditions:"
                fields={[
                    { key: 'properParking', label: 'Demonstrates proper parking', type: 'checkbox' },
                    { key: 'hazardousAreas', label: 'Awareness of dangerous or hazardous areas', type: 'checkbox' },
                    { key: 'awareOfEmployees', label: 'Awareness of other employees', type: 'checkbox' },
                    { key: 'otherEnabled', label: 'Other', type: 'checkbox' },
                    { key: 'other', label: 'Other', type: 'text', conditionalOn: 'otherEnabled' },
                ]}
                values={formData.section10}
                onFieldChange={(key, value) => handleFieldChange('section10', key, value)}
            />

            {/* Signature */}
            <Box
                sx={{
                    mt: 3,
                    p: 2,
                    border: '1px solid #ccc',
                    borderRadius: '8px',
                    backgroundColor: '#f9f9f9'
                }}
            >

                {/* Section 11 */}
                <TrainingFormSection
                    title=""
                    fields={[
                        { key: 'success', label: 'Successfully completed maneuvering practice drills.', type: 'checkboxLeft' }
                    ]}
                    values={formData.section11}
                    onFieldChange={(key, value) => handleFieldChange('section11', key, value)}
                />



                {formData.signature ? (
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', gap: 2 }}>
                        <img
                            src={formData.signature}
                            alt="Signature"
                            style={{ maxWidth: '200px', border: '1px solid #aaa' }}
                        />
                        <Button
                            variant="contained"
                            onClick={() => setShowSignatureModal(true)}
                        >
                            Change Signature
                        </Button>
                    </Box>
                ) : (
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                        <Button
                            variant="contained"
                            onClick={() => setShowSignatureModal(true)}
                        >
                            Add Signature
                        </Button>
                    </Box>
                )}



            </Box>



            {/* Buttons */}
            <Box sx={{ textAlign: 'right', mt: 4 }}>

                <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleSend}
                    disabled={formData.status !== TRAINING_FORM_STATUS_FINISH}
                >
                    Send
                </Button>
            </Box>

            {showSignatureModal && (
                <SignatureModal
                    existingSignature={formData.signature}
                    onClose={() => setShowSignatureModal(false)}
                    onSave={handleSignatureSaved}
                />
            )}
        </Box>
    );
}
