import React from 'react';
import {
    Typography,
    Grid,
    Checkbox,
    FormControlLabel,
    TextField,
    Box,
    Paper
} from '@mui/material';

export default function TrainingFormSection({ title, fields, values, onFieldChange }) {
    return (


        <Paper
            sx={{
                p: 2,
                mb: 2,
                backgroundColor: '#f9fafa',
                borderRadius: 2,
                border: '1px solid #ddd'
            }}
            elevation={0}
        >


            {title && (
                <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', mb: 2 }}>
                    {title}
                </Typography>
            )}

            <Grid container spacing={2}>
                {fields.map((field) => {
                    // 1) Если у поля есть условие "conditionalOn", но оно == false, не отображаем
                    if (field.conditionalOn && !values[field.conditionalOn]) {
                        return null;
                    }

                    // 2) Если это пара "otherEnabled" + "other" (groupedWith),
                    //    ниже мы обрабатываем их вместе, чтобы поле было под чекбоксом.
                    if (field.groupedWith) {
                        return null; // Пропускаем их здесь, т.к. выводим отдельным блоком.
                    }

                    // 3) Обычный рендер: чекбокс или текст
                    if (field.type === 'checkbox') {
                        return (
                            <Grid item xs={12} sm={6} md={4} key={field.key}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={!!values[field.key]}
                                            onChange={() => onFieldChange(field.key, !values[field.key])}
                                        />
                                    }
                                    label={field.label}
                                />
                            </Grid>
                        );
                    }

                    if (field.type === 'checkboxLeft') {
                        return (
                            <Grid  item xs={12} sm={12} md={12} key={field.key}>
                                <FormControlLabel
                                    label={field.label}
                                    control={
                                        <Checkbox
                                            checked={!!values[field.key]}
                                            onChange={() => onFieldChange(field.key, !values[field.key])}
                                        />
                                    }
                                />
                            </Grid>
                        );
                    }

                    if (field.type === 'text') {
                        return (
                            <Grid item xs={12} sm={6} md={4} key={field.key}>
                                <TextField
                                    fullWidth
                                    size="small"
                                    label={field.label}
                                    value={values[field.key] || ''}
                                    onChange={(e) => onFieldChange(field.key, e.target.value)}
                                />
                            </Grid>
                        );
                    }

                    return null;
                })}

                {/* 4) Обрабатываем поля "otherEnabled" + "other" в одной ячейке (чекбокс + поле под ним). */}
                {fields
                    .filter((field) => field.groupedWith)
                    .map((field) => {
                        // Предположим field.key === 'otherEnabled', field.groupedWith === 'other'.
                        const textFieldKey = field.groupedWith; // 'other'
                        const textFieldDef = fields.find((f) => f.key === textFieldKey);
                        if (!textFieldDef) return null;

                        const checkboxValue = !!values[field.key]; // otherEnabled
                        const textValue = values[textFieldKey] || '';

                        return (
                            <Grid item xs={12} sm={6} md={4} key={field.key}>
                                {/* Сам чекбокс "Other" */}
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={checkboxValue}
                                            onChange={() => {
                                                const newVal = !checkboxValue;
                                                onFieldChange(field.key, newVal);
                                                if (!newVal) {
                                                    // Если отключили чекбокс — стираем текст
                                                    onFieldChange(textFieldKey, '');
                                                }
                                            }}
                                        />
                                    }
                                    label={field.label}
                                />

                                {/* Поле ввода "Other" ПОД чекбоксом в том же Grid item */}
                                {checkboxValue && (
                                    <Box sx={{ mt: 1, ml: 4 }}>
                                        <TextField
                                            fullWidth
                                            size="small"
                                            label={textFieldDef.label}
                                            value={textValue}
                                            onChange={(e) => onFieldChange(textFieldKey, e.target.value)}
                                        />
                                    </Box>
                                )}
                            </Grid>
                        );
                    })}
            </Grid>
        </Paper>
    );
}
