// VersionStatus.js
import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';

function VersionStatus() {
    const theme = useTheme();
    const currentVersion = process.env.REACT_APP_VERSION;

    return (
        <Box
            sx={{
                display: 'inline-flex',
                backgroundColor: theme.palette.background.paper,
                padding: '8px 12px',
                borderRadius: '20px',
                boxShadow: theme.shadows[3],
                opacity: 0.9,
            }}
        >
            <Typography
                variant="body2"
                sx={{ color: theme.palette.text.secondary, fontWeight: 'bold' }}
            >
                v{currentVersion}
            </Typography>
        </Box>
    );
}

export default VersionStatus;
