// axiosInstance.js
import axios from 'axios';
import {URL} from "./config/constants";

// Создаем экземпляр axios
const axiosInstance = axios.create({
    baseURL: URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

// Interceptor для добавления токена в заголовок
axiosInstance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

// Interceptor для обработки истекшего токена
axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;
        console.log('Interceptor error');
        console.log(error);
        if (error.response && error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            try {
                const refreshToken = localStorage.getItem('refreshToken');
                const response = await axios.post(URL+'/token/refresh', {
                    refresh_token: refreshToken,
                });

                const { access_token, refresh_token } = response.data;

                // Обновляем токены в localStorage
                localStorage.setItem('token', access_token);
                localStorage.setItem('refreshToken', refresh_token);

                // Обновляем заголовок с новым токеном
                axiosInstance.defaults.headers.Authorization = `Bearer ${access_token}`;
                originalRequest.headers.Authorization = `Bearer ${access_token}`;

                // Повторяем исходный запрос с новым токеном
                return axiosInstance(originalRequest);
            } catch (err) {
                console.log(err);
                debugger;
                // Если обновить токен не удалось, перенаправляем на логин
                localStorage.removeItem('token');
                localStorage.removeItem('refreshToken');
                window.location.href = '/#login';
                return Promise.reject(err);
            }
        }

        return Promise.reject(error);
    }
);

export default axiosInstance;
