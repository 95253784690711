// App.js
import React, { useState, useEffect } from 'react';
import useAuth from './hooks/useAuth';
import useOrientation from './hooks/useOrientation';
import OrientationWarning from './components/OrientationWarning';
import AuthenticatedLayout from './layouts/AuthenticatedLayout';
import UnauthenticatedLayout from './layouts/UnauthenticatedLayout';
import SWUpdatePrompt from './components/SWUpdatePrompt';
import useServiceWorkerUpdates from './hooks/useServiceWorkerUpdates';

function App() {
    const { isAuthenticated, handleLogout } = useAuth();
    const { isLandscape, isMobile } = useOrientation();
    const { waitingWorker, updateApp } = useServiceWorkerUpdates();

    const [isModalOpen, setModalOpen] = useState(false);

    // Если появилась новая версия, можно по желанию автоматически открыть модалку,
    // или открыть по клику:
    // Здесь решаем: открывать ли модалку сразу
    useEffect(() => {
        if (waitingWorker) {
            // Можете решить автоматом открывать модалку:
            //setModalOpen(true);
        }
    }, [waitingWorker]);

    if (!isLandscape && isMobile) {
        return <OrientationWarning />;
    }

    return (
        <>
            <SWUpdatePrompt
                open={isModalOpen}
                onClose={() => setModalOpen(false)}
                waitingWorker={waitingWorker}
                updateApp={updateApp}
            />
            {isAuthenticated ? (
                <AuthenticatedLayout handleLogout={handleLogout}
                                     onShowUpdateModal={() => setModalOpen(true)}
                                     waitingWorker={waitingWorker}/>
            ) : (
                <UnauthenticatedLayout />
            )}
        </>
    );
}

export default App;
