import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { syncAllData, syncDataGeneric } from '../../services/syncService';
import { syncConfig } from '../../config/syncConfig';
import SyncActions from './SyncActions';
import SyncGrid from './SyncGrid';

const Sync = () => {
    // loadingStates — объект, где ключи = ключам из syncConfig: employees, instructions, ...
    const [loadingStates, setLoadingStates] = useState(() => {
        const initialState = {};
        Object.keys(syncConfig).forEach((key) => {
            initialState[key] = false;
        });
        return initialState;
    });

    const [overallSyncTime, setOverallSyncTime] = useState(localStorage.getItem('lastOverallSyncTime') || 'Never');

    // Собираем массив { id, name, syncTime } для DataGrid, чтобы отрисовать.
    // (Ключи syncConfig + соответствующие localStorageKey)
    const [syncData, setSyncData] = useState([]);

    // Заполняем syncData при монтировании
    useEffect(() => {
        const newSyncData = Object.entries(syncConfig).map(([key, cfg], i) => {
            const lastSyncTime = localStorage.getItem(cfg.localStorageKey)
                || localStorage.getItem('lastOverallSyncTime')
                || 'Never';
            return {
                id: i + 1,
                key, // например: 'employees'
                name: `${key.charAt(0).toUpperCase()}${key.slice(1)} Data`,
                syncTime: lastSyncTime
            };
        });

        setSyncData(newSyncData);
    }, []);

    // Следим за кастомным событием 'syncComplete'
    useEffect(() => {
        const handleSyncComplete = () => {
            setOverallSyncTime(localStorage.getItem('lastOverallSyncTime') || 'Never');
        };

        window.addEventListener('syncComplete', handleSyncComplete);
        return () => {
            window.removeEventListener('syncComplete', handleSyncComplete);
        };
    }, []);

    // Универсальный «синк» по ключу (employees, instructions и т.д.)
    const handleSyncKey = async (key) => {
        setLoadingStates((prev) => ({ ...prev, [key]: true }));
        await syncDataGeneric(key);
        setLoadingStates((prev) => ({ ...prev, [key]: false }));

        // Обновим syncTime для этой сущности и в таблице
        const updatedSyncTime = localStorage.getItem(syncConfig[key].localStorageKey) || 'Never';
        setSyncData((prevData) =>
            prevData.map((item) =>
                item.key === key ? { ...item, syncTime: updatedSyncTime } : item
            )
        );
    };

    // Запуск «синхронизации всего»
     const handleSyncAll = async () => {
        // Ставим всем loading = true
        setLoadingStates((prev) => {
            const updated = {};
            for (const k in prev) {
                updated[k] = true;
            }
            return updated;
        });

        await syncAllData();

        // Ставим всем loading = false
        setLoadingStates((prev) => {
            const updated = {};
            for (const k in prev) {
                updated[k] = false;
            }
            return updated;
        });

        // Обновим все lastSyncTime в таблице
        setSyncData((prevData) =>
            prevData.map((item) => {
                const lastTime = localStorage.getItem(syncConfig[item.key].localStorageKey)
                    || localStorage.getItem('lastOverallSyncTime')
                    || 'Never';
                return { ...item, syncTime: lastTime };
            })
        );
    };

    return (
        <Box sx={{ p: 4 }}>
            <Typography variant="h4" gutterBottom>
                Data Synchronization
            </Typography>
            <SyncActions
                overallSyncTime={overallSyncTime}
                loadingStates={loadingStates}
                onSyncAll={handleSyncAll}
            />
            <SyncGrid
                syncData={syncData}
                loadingStates={loadingStates}
                onSyncKey={handleSyncKey}
            />
        </Box>
    );
};

export default Sync;
