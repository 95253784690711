// TBTAdd.jsx
import React, { useEffect, useState } from 'react';
import { Button, Container, Typography, Box, TextField, Autocomplete } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { initializeDB } from '../../config/db';
import { WEEKLY_INSTRUCTION_STORE, INSTRUCTION_STORE } from '../../config/constants';
import { getCurrentWeekNumber } from '../../utils/utils';  // Импортируем из utils
import InstructionTextModal from './InstructionTextModal';

const TBTAdd = () => {
    const navigate = useNavigate();
    const [instructions, setInstructions] = useState([]);
    const [selectedInstruction, setSelectedInstruction] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState('');

    // Функция для получения всех инструкций из IndexedDB
    const fetchInstructions = async () => {
        try {
            const db = await initializeDB();

            if (!db.objectStoreNames.contains(INSTRUCTION_STORE)) {
                console.warn("Instruction store is missing.");
                return;
            }

            const tx = db.transaction(INSTRUCTION_STORE, 'readonly');
            const store = tx.objectStore(INSTRUCTION_STORE);
            const allInstructions = await store.getAll();

            setInstructions(allInstructions.map((instruction) => ({ ...instruction })));
        } catch (error) {
            console.error("Error fetching instructions:", error);
        }
    };

    useEffect(() => {
        fetchInstructions();
    }, []);

    // Просмотр текста инструкции на выбранном языке
    const handleViewContent = (language) => {
        if (selectedInstruction) {
            const content = language === 'english'
                ? selectedInstruction.textEnglish
                : selectedInstruction.textSpanish;
            setModalContent(content);
            setModalOpen(true);
        }
    };

    // Сохранение инструкции в WEEKLY_INSTRUCTION_STORE
    const handleSave = async () => {
        if (!selectedInstruction) {
            alert('Please select an instruction');
            return;
        }

        try {
            const db = await initializeDB();

            if (!db.objectStoreNames.contains(WEEKLY_INSTRUCTION_STORE)) {
                console.warn("Weekly Instructions store is missing.");
                return;
            }

            const tx = db.transaction(WEEKLY_INSTRUCTION_STORE, 'readwrite');
            const store = tx.objectStore(WEEKLY_INSTRUCTION_STORE);

            const currentWeekNumber = getCurrentWeekNumber();
            const newInstruction = {
                id: selectedInstruction.id,
                title: selectedInstruction.Title,
                TBTNo: selectedInstruction.TBTNo,
                instructionId: selectedInstruction.id,
                date: selectedInstruction.WEDate,
                uniqueId: `${selectedInstruction.id}-${currentWeekNumber}-${Date.now()}`,
                weekNumber: currentWeekNumber,
                status: 'new',
                lastSentAt: null,
                signedEmployees: [],
            };

            await store.add(newInstruction);
            alert('Instruction saved successfully');
            setSelectedInstruction(null);

            // Отправляем событие для обновления других компонентов
            const instructionAddedEvent = new CustomEvent('instructionStatusChanged', { detail: 'updated' });
            window.dispatchEvent(instructionAddedEvent);

            navigate('/tbt');

        } catch (error) {
            console.error("Error saving instruction:", error);
            alert("Failed to save instruction. Please try again.");
        }
    };

    return (
        <Container
            maxWidth="sm"
            sx={{
                padding: '16px',
                backgroundColor: '#f9f9f9',
                borderRadius: '8px'
            }}
        >
            <Typography variant="h5" gutterBottom>
                Create Instruction
            </Typography>

            <Autocomplete
                options={instructions}
                getOptionLabel={(option) => option.Title || 'Unnamed'}
                onChange={(event, newValue) => setSelectedInstruction(newValue)}
                value={selectedInstruction}
                renderInput={(params) => <TextField {...params} label="Select an Instruction" fullWidth required />}
                sx={{ mb: 2 }}
            />

            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                <Button
                    variant="outlined"
                    onClick={() => handleViewContent('english')}
                    disabled={!selectedInstruction}
                >
                    View Text (ENG)
                </Button>
                <Button
                    variant="outlined"
                    onClick={() => handleViewContent('spanish')}
                    disabled={!selectedInstruction}
                >
                    View Text (SPA)
                </Button>
            </Box>

            <Button
                variant="contained"
                color="primary"
                onClick={handleSave}
                fullWidth
                sx={{ mt: 2 }}
                disabled={!selectedInstruction}
            >
                Save Instruction
            </Button>

            {/* Модальное окно с текстом инструкции */}
            <InstructionTextModal
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                title={selectedInstruction ? selectedInstruction.Title : 'Instruction Text'}
                content={modalContent}
            />
        </Container>
    );
};

export default TBTAdd;
