import axiosInstance from '../axiosInstance';
import { initializeDB } from '../config/db';
import { syncConfig } from '../config/syncConfig';

/**
 * Базовая функция синхронизации для большинства сущностей.
 * Если у элемента syncConfig нет кастомного syncHandler, используем дефолт:
 *   - clear + put все записи
 */
async function defaultSyncHandler(db, storeName, data) {
    const tx = db.transaction(storeName, 'readwrite');
    const store = tx.objectStore(storeName);
    await store.clear();
    for (const record of data) {
        await store.put(record);
    }
    await tx.done;
}

export async function syncDataGeneric(key) {
    // key — это строка, соответствующая «employees», «instructions» и т.д. из syncConfig
    const configItem = syncConfig[key];
    if (!configItem) {
        console.error(`syncDataGeneric: нет конфигурации для ключа '${key}'`);
        return;
    }
    const db = await initializeDB();

    try {

        console.log(configItem.endpoint);
        const response = await axiosInstance.get(configItem.endpoint);
        const data = response.data;

        // Если есть кастомный handler, вызываем его, иначе — дефолт.
        if (typeof configItem.syncHandler === 'function') {
            await configItem.syncHandler(db, configItem.storeName, data);
        } else {
            await defaultSyncHandler(db, configItem.storeName, data);
        }

        // Записываем время
        localStorage.setItem(configItem.localStorageKey, new Date().toISOString());
        console.log(`Синхронизация '${key}' прошла успешно!`);
    } catch (error) {
        console.error(`Ошибка синхронизации '${key}':`, error);
    }
}

/**
 * Синхронизируем ВСЁ (по всем ключам из syncConfig).
 */
export async function syncAllData() {
    const db = await initializeDB();
    try {
        // Последовательная синхронизация:
        for (const key of Object.keys(syncConfig)) {
            await syncDataGeneric(key);
        }

        // Записываем в localStorage «глобальное время»
        const currentTime = new Date().toISOString();
        localStorage.setItem('lastOverallSyncTime', currentTime);

        // Диспетчим кастомное событие
        const syncEvent = new CustomEvent('syncComplete', { detail: currentTime });
        window.dispatchEvent(syncEvent);
    } catch (error) {
        console.error('Ошибка синхронизации всех данных:', error);
    }
}

/**
 * Пример функции «clearAllData», оставляем как было, если надо чистить всю IndexedDB сразу.
 */
export const clearAllData = async () => {
    try {
        const dbNames = ['dhgriffin'];
        for (const dbName of dbNames) {
            await new Promise((resolve, reject) => {
                const request = indexedDB.deleteDatabase(dbName);
                request.onsuccess = () => {
                    console.log(`База данных ${dbName} успешно удалена`);
                    resolve();
                };
                request.onerror = (event) => {
                    console.error(`Ошибка при удалении базы данных ${dbName}:`, event.target.error);
                    reject(event.target.error);
                };
                request.onblocked = () => {
                    console.warn(`Удаление базы данных ${dbName} заблокировано`);
                };
            });
        }
        console.log('Все базы данных IndexedDB успешно удалены');
    } catch (error) {
        console.error('Ошибка при очистке данных из IndexedDB:', error);
    }
};
