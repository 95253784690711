// utils/utils.js
import { initializeDB } from '../config/db';
import { EMPLOYEE_STORE, INSTRUCTION_STORE } from '../config/constants';

export const getCurrentWeekNumber = () => {
    const currentDate = new Date();
    const startOfYear = new Date(currentDate.getFullYear(), 0, 1);
    const days = Math.floor((currentDate - startOfYear) / (24 * 60 * 60 * 1000));
    return Math.ceil((days + startOfYear.getDay() + 1) / 7);
};

export const fetchEmployees = async (ids) => {
    const db = await initializeDB();
    const tx = db.transaction(EMPLOYEE_STORE, 'readonly');
    const store = tx.objectStore(EMPLOYEE_STORE);
    const employees = await Promise.all(ids.map((id) => store.get(id)));
    return employees.filter(Boolean);
};

export const fetchInstructionText = async (instructionId, language) => {
    const db = await initializeDB();
    const store = db.transaction(INSTRUCTION_STORE, 'readonly').objectStore(INSTRUCTION_STORE);
    const textData = await store.get(instructionId);
    return textData ? textData[language === 'english' ? 'textEnglish' : 'textSpanish'] : null;
};

export function formatUSDateNoTime(value) {
    if (!value) return 'N/A';
    const parsed = new Date(value);
    if (isNaN(parsed.getTime())) {
        return value; // если невалидно
    }
    return parsed.toLocaleDateString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
    });
}