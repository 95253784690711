// components/tbt/InstructionInfo.jsx
import React from 'react';
import {Box, Typography, Button, Card, Chip} from '@mui/material';

const InstructionInfo = ({ instruction, onViewEnglish, onViewSpanish }) => {
    return (
        <Card sx={{ mb: 4, p: 3, backgroundColor: '#f0f4fa', borderRadius: 2 }}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Box>
                    <Typography variant="h4" sx={{ mb: 1 }}>
                        {instruction?.title}
                    </Typography>


                        {instruction.status && (
                            <Chip
                                label={`Status: ${instruction.status}`}
                                color={instruction.status === 'process' ? 'warning' : 'default'}
                                sx={{  mb: 1, fontWeight:"bold" }}
                            />
                        )}



                    <Typography variant="subtitle1">
                        <strong>Date:</strong>{' '}
                        {instruction?.date?.date ? new Date(instruction.date.date).toLocaleDateString('en-US', {
                            month: '2-digit',
                            day: '2-digit',
                            year: 'numeric'
                        }) : 'N/A'}
                    </Typography>
                </Box>
                <Box display="flex" flexDirection="column" gap={2} alignItems="flex-start">
                    <Button variant="contained" onClick={onViewEnglish} color="primary">
                        View English Text
                    </Button>
                    <Button variant="contained" onClick={onViewSpanish} color="primary">
                        View Spanish Text
                    </Button>
                </Box>
            </Box>
        </Card>
    );
};

export default InstructionInfo;
