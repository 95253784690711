import React from 'react';
import { Box, Button } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

const SyncGrid = ({ syncData, loadingStates, onSyncKey }) => {
    const columns = [
        { field: 'name', headerName: 'Data Type', flex: 1 },
        {
            field: 'syncTime',
            headerName: 'Last Sync Time',
            flex: 1,
        },
        {
            field: 'actions',
            headerName: 'Actions',
            flex: 1,
            renderCell: (params) => {
                const { key, name } = params.row;
                const isLoading = loadingStates[key];

                return (
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => onSyncKey(key)}
                        disabled={isLoading}
                        sx={{ mt: 1 }}
                    >
                        {isLoading ? 'Syncing...' : `Sync ${name}`}
                    </Button>
                );
            },
        },
    ];

    return (
        <Box
            sx={{
                height: 400,
                width: '100%',
                bgcolor: 'background.paper',
                borderRadius: 1,
                boxShadow: 3,
                p: 2,
            }}
        >
            <DataGrid
                rows={syncData}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5]}
                disableSelectionOnClick
            />
        </Box>
    );
};

export default SyncGrid;
