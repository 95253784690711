import React, { useState, useCallback, useMemo } from 'react';
import {
    AppBar,
    Toolbar,
    Box,
    IconButton,
    MenuItem,
    MenuList,
    Popper,
    Paper,
    ClickAwayListener,
    Grow,
    Divider,
    ListItemIcon,
    ListItemText,
    useMediaQuery,
    useTheme,
    Menu
} from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

import logo from '../../assets/logo.png';
import { useOnlineStatus } from '../../hooks/useOnlineStatus';
import { useSyncTime } from '../../hooks/useSyncTime';
import { useInstructionCounts } from '../../hooks/useInstructionCounts';
import { syncAllData } from '../../services/syncService';

import UserStatus from './UserStatus';
import NetworkStatus from './NetworkStatus';
import SyncStatus from './SyncStatus';
import InstructionsCount from './InstructionsCount';
import {MAIN_MENU_ITEMS} from "../../config/menu";

const Header = ({ handleLogout }) => {
    const isOnline = useOnlineStatus();
    const lastSyncTime = useSyncTime();
    const { processedCount, newCount, showProcessed } = useInstructionCounts();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const location = useLocation();

    const user = useMemo(() => ({
        firstName: localStorage.getItem('firstName') || 'Unknown',
        lastName: localStorage.getItem('lastName') || 'User',
        role: localStorage.getItem('role') || 'Unknown',
    }), []);

    // Состояния для главного меню и подпункта
    const [menuAnchor, setMenuAnchor] = useState(null);
    const [subMenuAnchor, setSubMenuAnchor] = useState(null);
    const [activeSubMenuId, setActiveSubMenuId] = useState(null);

    // Меню пользователя (иконка профиля)
    const [userMenuAnchor, setUserMenuAnchor] = useState(null);

    // ----------------------
    // Обработчики
    // ----------------------

    // Открыть/закрыть главное меню
    const handleMenuOpen = useCallback((e) => {
        setMenuAnchor(e.currentTarget);
    }, []);

    const handleMenuClose = useCallback(() => {
        setMenuAnchor(null);
        setSubMenuAnchor(null);
        setActiveSubMenuId(null);
    }, []);

    // Открыть подменю
    const handleSubMenuOpen = useCallback((e, itemIndex) => {
        setActiveSubMenuId(itemIndex);
        setSubMenuAnchor(e.currentTarget);
    }, []);

    // Меню пользователя
    const handleUserMenuOpen = useCallback((event) => {
        setUserMenuAnchor(event.currentTarget);
    }, []);

    const handleUserMenuClose = useCallback(() => {
        setUserMenuAnchor(null);
    }, []);

    return (
        <AppBar position="fixed" sx={{ backgroundColor: '#1976d2' }}>
            <Toolbar sx={{ justifyContent: 'space-between' }}>
                {/* Левая часть: лого и бургер-меню */}
                <Box display="flex" alignItems="center">
                    <Link to="/">
                        <img
                            src={logo}
                            alt="Logo"
                            style={{ maxHeight: '40px', marginRight: '15px' }}
                        />
                    </Link>

                    <IconButton color="inherit" onClick={handleMenuOpen}>
                        <MenuIcon />
                    </IconButton>

                    {/* Главное меню */}
                    <Popper
                        open={Boolean(menuAnchor)}
                        anchorEl={menuAnchor}
                        role={undefined}
                        transition
                        disablePortal
                        style={{ zIndex: 1300 }}
                    >
                        {({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                                style={{
                                    transformOrigin:
                                        placement === 'bottom' ? 'center top' : 'center bottom',
                                }}
                            >
                                <Paper>
                                    <ClickAwayListener onClickAway={handleMenuClose}>
                                        <MenuList autoFocusItem={Boolean(menuAnchor)}>
                                            {MAIN_MENU_ITEMS.map((item, idx, arr) => {
                                                const hasSubMenu = item.subMenu?.length > 0;

                                                // Определяем, надо ли отрисовать Divider ПОСЛЕ данного пункта
                                                // (не рисуем, если это последний элемент списка)
                                                const showDivider = idx < arr.length - 1;

                                                return [
                                                    <MenuItem
                                                        key={`menu-item-${item.label}`}
                                                        component={item.link ? Link : 'div'}
                                                        to={item.link || undefined}
                                                        onClick={
                                                            hasSubMenu
                                                                ? undefined // Если есть подпункты, кликом не закрываем меню
                                                                : handleMenuClose
                                                        }
                                                        onMouseEnter={
                                                            hasSubMenu
                                                                ? (e) => handleSubMenuOpen(e, idx)
                                                                : () => setActiveSubMenuId(null)
                                                        }
                                                        selected={
                                                            !!item.link && location.pathname === item.link
                                                        }
                                                        sx={{
                                                            display: 'flex',
                                                            justifyContent: 'space-between',
                                                            width: '200px',
                                                        }}
                                                    >
                                                        <Box display="flex" alignItems="center">
                                                            {item.icon && (
                                                                <ListItemIcon>{item.icon}</ListItemIcon>
                                                            )}
                                                            <ListItemText>{item.label}</ListItemText>
                                                        </Box>
                                                        {hasSubMenu && <ArrowRightIcon fontSize="small" />}
                                                    </MenuItem>,

                                                    showDivider && (
                                                        <Divider key={`divider-${item.label}`} />
                                                    ),
                                                ];
                                            })}
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>

                    {/* Подменю (второй Popper) */}
                    <Popper
                        open={Boolean(subMenuAnchor) && activeSubMenuId !== null}
                        anchorEl={subMenuAnchor}
                        placement="right-start"
                        disablePortal
                        style={{ zIndex: 1300 }}
                    >
                        <Paper>
                            <MenuList>
                                {activeSubMenuId != null &&
                                    MAIN_MENU_ITEMS[activeSubMenuId]?.subMenu?.map(
                                        (subItem, subIdx, subArr) => {
                                            const showDivider = subIdx < subArr.length - 1;
                                            return [
                                                <MenuItem
                                                    key={`sub-menu-item-${subItem.label}`}
                                                    component={Link}
                                                    to={subItem.link}
                                                    onClick={handleMenuClose}
                                                >
                                                    {subItem.label}
                                                </MenuItem>,

                                                showDivider && (
                                                    <Divider key={`sub-divider-${subItem.label}`} />
                                                ),
                                            ];
                                        }
                                    )}
                            </MenuList>
                        </Paper>
                    </Popper>
                </Box>

                <Box display="flex" alignItems="center" gap={2}>
                    {isMobile ? (
                        <SyncStatus lastSyncTime={lastSyncTime} onSync={syncAllData} />
                    ) : (
                        <>
                            <UserStatus user={user} />
                            <NetworkStatus isOnline={isOnline} />
                            <SyncStatus lastSyncTime={lastSyncTime} onSync={syncAllData} />
                            <InstructionsCount
                                processedCount={processedCount}
                                newCount={newCount}
                                showProcessed={showProcessed}
                            />
                        </>
                    )}

                    <IconButton color="inherit" onClick={handleUserMenuOpen}>
                        <AccountCircleIcon />
                    </IconButton>
                    <Menu
                        anchorEl={userMenuAnchor}
                        open={Boolean(userMenuAnchor)}
                        onClose={handleUserMenuClose}
                    >
                        <MenuItem onClick={handleLogout}>Logout</MenuItem>
                    </Menu>
                </Box>
            </Toolbar>
        </AppBar>
    );
};

export default Header;
