import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { clearAllData } from '../services/syncService';

function useAuth() {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const checkAuthentication = () => {
            const token = localStorage.getItem('token');
            if (token) {
                setIsAuthenticated(true);
            } else {
                setIsAuthenticated(false);
                if (navigator.onLine) {
                    navigate('/#login');
                }
            }
        };

        checkAuthentication();

        window.addEventListener('online', checkAuthentication);
        window.addEventListener('offline', checkAuthentication);

        return () => {
            window.removeEventListener('online', checkAuthentication);
            window.removeEventListener('offline', checkAuthentication);
        };
    }, [navigate]);

    const handleLogout = () => {
        setIsAuthenticated(false);
        clearAllData();
        localStorage.clear();
        navigate('/#login');
    };

    return { isAuthenticated, handleLogout };
}

export default useAuth;
