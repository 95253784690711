import { initializeDB } from '../config/db'; // Импорт функции для инициализации базы данных
import { INSTRUCTION_STORE, WEEKLY_INSTRUCTION_STORE } from '../config/constants'; // Константы для хранения в IndexedDB

export const createWeeklyInstructions = async () => {
    const db = await initializeDB();

    try {
        // Открываем транзакцию для работы с инструкциями
        const txInstructions = db.transaction(INSTRUCTION_STORE, 'readonly');
        const storeInstructions = txInstructions.objectStore(INSTRUCTION_STORE);

        // Получаем все активные инструкции
        const activeInstructions = await storeInstructions.getAll();
        const instructionsToProcess = activeInstructions.filter(instruction => instruction.status === 'active');

        // Открываем транзакцию для работы с еженедельными инструкциями
        const txWeeklyInstructions = db.transaction(WEEKLY_INSTRUCTION_STORE, 'readwrite');
        const storeWeeklyInstructions = txWeeklyInstructions.objectStore(WEEKLY_INSTRUCTION_STORE);

        // Получаем текущий номер недели
        const currentWeekNumber = getCurrentWeekNumber();

        // Обрабатываем каждую активную инструкцию и создаем соответствующую weekly instruction
        for (const instruction of instructionsToProcess) {
            // Проверяем, существует ли запись с таким же instructionId в weeklyInstructions
            const existingWeeklyInstructions = await storeWeeklyInstructions.getAll();
            const instructionExists = existingWeeklyInstructions.some(weeklyInstruction => weeklyInstruction.instructionId === instruction.id);

            if (!instructionExists) {
                // Если записи не существует, добавляем новую
                const weeklyInstruction = {
                    id: `wi-${instruction.TBTNo}-${currentWeekNumber}`, // Уникальный идентификатор
                    TBTNo: instruction.TBTNo,
                    instructionId: instruction.id,
                    uniqueId: `${instruction.id}-${currentWeekNumber}`,
                    weekNumber: currentWeekNumber,
                    date: instruction.WEDate, // Используем дату из инструкции
                    title: instruction.Title,
                    signedEmployees: [],
                    status: 'new', // Статус 'new'
                    lastSentAt: null, // Пустое значение для времени последней отправки
                };

                // Добавляем запись в базу данных
                await storeWeeklyInstructions.add(weeklyInstruction);
            }
        }


        await txWeeklyInstructions.done;

        // Создаем пользовательское событие для уведомления других компонентов
        const instructionAddedEvent = new CustomEvent('instructionStatusChanged', { detail: 'updated' });
        window.dispatchEvent(instructionAddedEvent);
    } catch (error) {
        console.error('Ошибка при создании еженедельных инструкций:', error);
    }
};

// Вспомогательная функция для получения текущего номера недели
const getCurrentWeekNumber = () => {
    const now = new Date();
    const start = new Date(now.getFullYear(), 0, 1);
    const diff = now - start + ((start.getTimezoneOffset() - now.getTimezoneOffset()) * 60 * 1000);
    const oneWeek = 1000 * 60 * 60 * 24 * 7;
    return Math.floor(diff / oneWeek) + 1;
};
