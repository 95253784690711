import { openDB } from 'idb';
import {
    EMPLOYEE_STORE,
    INSTRUCTION_STORE,
    TRAINING_DUE_STORE,
    COST_CODE_STORE,
    GL_CODE_STORE,
    SIGNED_DOCS_STORE,
    WEEKLY_INSTRUCTION_STORE,
    WEEK_LIST_STORE,
    MEP_FORM_STORE,
    FT_FORM_STORE,
    TRAINING_STORE,
    DB_VERSION, DB_NAME, CC_STORE, CODE_GROUP_STORE, JOB_STORE
} from './constants';

export const initializeDB = async () => {
    return openDB(DB_NAME, DB_VERSION, {
        upgrade(db) {
            // SignedDocumentsDB - signedDocuments
            if (!db.objectStoreNames.contains(SIGNED_DOCS_STORE)) {
                db.createObjectStore(SIGNED_DOCS_STORE, { keyPath: 'uniqueId', autoIncrement: true });
            }

            // SyncDB - employees
            if (!db.objectStoreNames.contains(EMPLOYEE_STORE)) {
                db.createObjectStore(EMPLOYEE_STORE, { keyPath: 'id', autoIncrement: true });
            }

            // SyncDB - instructions
            if (!db.objectStoreNames.contains(INSTRUCTION_STORE)) {
                db.createObjectStore(INSTRUCTION_STORE, { keyPath: 'id', autoIncrement: true });
            }

            // WeeklyInstructionsDB - weeklyInstructions
            if (!db.objectStoreNames.contains(WEEKLY_INSTRUCTION_STORE)) {
                db.createObjectStore(WEEKLY_INSTRUCTION_STORE, { keyPath: 'uniqueId', autoIncrement: true });
            }

            // WeeklyListDB - weeklyList
            if (!db.objectStoreNames.contains(WEEK_LIST_STORE)) {
                db.createObjectStore(WEEK_LIST_STORE, { keyPath: 'weekNumber', autoIncrement: true });
            }

            // trainingDB - training
            if (!db.objectStoreNames.contains(TRAINING_STORE)) {
                db.createObjectStore(TRAINING_STORE, { keyPath: 'id', autoIncrement: true });
            }

            //mepForm
            if (!db.objectStoreNames.contains(MEP_FORM_STORE)) {
                db.createObjectStore(MEP_FORM_STORE, { keyPath: 'id', autoIncrement: true });
            }

            // ftForm
            if (!db.objectStoreNames.contains(FT_FORM_STORE)) {
                db.createObjectStore(FT_FORM_STORE, { keyPath: 'id', autoIncrement: true });
            }

            // trainingDue
            if (!db.objectStoreNames.contains(TRAINING_DUE_STORE)) {
                db.createObjectStore(TRAINING_DUE_STORE, { keyPath: 'id', autoIncrement: true });
            }

            // costCode
            if (!db.objectStoreNames.contains(COST_CODE_STORE)) {
                db.createObjectStore(COST_CODE_STORE, { keyPath: 'id', autoIncrement: true });
            }

            // GLCode
            if (!db.objectStoreNames.contains(GL_CODE_STORE)) {
                db.createObjectStore(GL_CODE_STORE, { keyPath: 'id', autoIncrement: true });
            }

            // CC
            if (!db.objectStoreNames.contains(CC_STORE)) {
                db.createObjectStore(CC_STORE, { keyPath: 'id', autoIncrement: true });
            }

            // CODE_GROUP_STORE
            if (!db.objectStoreNames.contains(CODE_GROUP_STORE)) {
                db.createObjectStore(CODE_GROUP_STORE, { keyPath: 'id', autoIncrement: true });
            }

            // JOB_STORE
            if (!db.objectStoreNames.contains(JOB_STORE)) {
                db.createObjectStore(JOB_STORE, { keyPath: 'id', autoIncrement: true });
            }



        },
    });
};
