import React from 'react';
import { Box, Typography } from '@mui/material';
import WifiIcon from '@mui/icons-material/Wifi';
import WifiOffIcon from '@mui/icons-material/WifiOff';

const NetworkStatus = ({ isOnline }) => (
    <Box
        display="flex"
        alignItems="center"
        sx={{ backgroundColor: '#f5f5f5', padding: '5px 10px', borderRadius: '20px' }}
    >
        {isOnline ? (
            <>
                <WifiIcon color="success" sx={{ marginRight: '5px' }} />
                <Typography variant="body2" color="green">Online</Typography>
            </>
        ) : (
            <>
                <WifiOffIcon color="error" sx={{ marginRight: '5px' }} />
                <Typography variant="body2" color="red">Offline</Typography>
            </>
        )}
    </Box>
);

export default React.memo(NetworkStatus);
