import {useEffect, useState} from "react";
import {openDB} from "idb";
import {CC_STORE, DB_NAME, DB_VERSION, WEEKLY_INSTRUCTION_STORE} from "../config/constants";

export function useMainPageStatuses() {
    const [tbtStatuses, setTbtStatuses] = useState({});
    const [ccStatus, setCcStatus] = useState('');

    useEffect(() => {
        (async function fetchData() {
            try {
                // Открываем IndexedDB
                const db = await openDB(DB_NAME, DB_VERSION);

                // ----- TBT -----
                const txTbt = db.transaction(WEEKLY_INSTRUCTION_STORE, 'readonly');
                const storeTbt = txTbt.objectStore(WEEKLY_INSTRUCTION_STORE);
                const allTbt = await storeTbt.getAll();
                await txTbt.done;

                // Подсчитаем количество в каждом статусе
                const statusMap = {};
                for (const rec of allTbt) {
                    const st = rec.status || 'unknown';
                    statusMap[st] = (statusMap[st] || 0) + 1;
                }
                setTbtStatuses(statusMap);

                // ----- CC -----
                const txCc = db.transaction(CC_STORE, 'readonly');
                const storeCc = txCc.objectStore(CC_STORE);
                const cc1 = await storeCc.get(1);
                await txCc.done;

                setCcStatus(cc1 ? 'In Process' : '');
            } catch (error) {
                console.error('Ошибка в useMainPageStatuses:', error);
            }
        })();
    }, []);

    return { tbtStatuses, ccStatus };
}
