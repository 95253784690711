// src/components/training/ft/FtForm.jsx

import React, { useEffect, useState, useCallback } from 'react';
import { Box, Button, Typography } from '@mui/material';

import TrainingFormHeader from '../TrainingFormHeader';
import TrainingFormSection from '../TrainingFormSection';
import SignatureModal from '../SignatureModal';

// Сервис для “Forklift” формы (аналог mepFormService) – предположим ftFormService
import {
    saveFtForm,
    markFtFormSend,
    getAllFtForms
} from '../../../services/ftFormService';

import {
    TRAINING_FORM_STATUS_FINISH,
    TRAINING_FORM_STATUS_PROCESS,
    TRAINING_FORM_STATUS_SEND
} from '../../../config/constants';

import axiosInstance from '../../../axiosInstance';

export default function FtForm() {
    // “Форма по Forklift” – поля из вашего скриншота
    const defaultFormData = {
        operatorName: '',
        trainerName: '',
        date: new Date().toISOString().split('T')[0],

        // Раздел 7: ability to demonstrate forklift controls
        section7: {
            clutch: false,
            tiltControl: false,
            attachmentControl: false,
            serviceBrake: false,
            inchingControl: false,
            liftControl: false,
            steeringTechniques: false,
            otherEnabled: false,
            other: '',
        },

        // 8. Proper positioning
        section8: {
            within25Feet: false,
            over25FeetNotInView: false,
            positionControlNotInView: false,
            useChocksOnIncline: false,
        },

        // 9. Demonstration of proper forklift operation
        section9: {
            properMounting: false,
            seatBelts: false,
            directionOfTravel: false,
            carryingForksLowered: false,
            appropriateSpeeds: false,
            carryingLoadUp: false,
            carryingLoadDown: false,
            adjustmentWetSurface: false,
            cornering: false,
            soundingHorn: false,
            otherEnabled: false,
            other: '',
        },

        // 10. Loading
        section10: {
            properBalancing: false,
            properSize: false,
            properCapacity: false,
            loadCradledBack: false,
            otherEnabled: false,
            other: '',
        },

        // 11. Driving with load
        section11: {
            maintainingProper: false,
            centeringLoads: false,
            HandlingLoad: false,
            smoothStartingBraking: false,
            travelingThreeForkLengths: false,
            otherEnabled: false,
            other: '',
        },

        // 12. Unloading and Stacking
        section12: {
            stackingSquarely: false,
            depositingLoadSafely: false,
            properHeightTieredLoad: false,
            properlyDepositing: false,
            removingLoadTravel: false,
            usingProper: false,
            removingLoadRack: false,
            otherEnabled: false,
            other: '',
        },

        // 13. Workplace conditions
        section13: {
            demonstratesParking: false,
            demonstratesLoadPlacement: false,
            awareDangerousHazards: false,
            areasNotPlaceLoads: false,
            awareFireEquipment: false,
            awareEmployees: false,
            awareAisles: false,
            awareExits: false,
            otherEnabled: false,
            other: '',
        },
        section14:{
            success: false,
        },
        // Completed, signature, status
        completed: false,
        signature: null,
        status: TRAINING_FORM_STATUS_PROCESS,
    };

    const [formData, setFormData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [showSignatureModal, setShowSignatureModal] = useState(false);

    // useEffect: грузим ВСЕ ftForm из IndexedDB, фильтруем status != SEND
    useEffect(() => {
        (async () => {
            try {
                const allForms = await getAllFtForms();
                // убираем SEND
                const activeForms = allForms.filter(f => f.status !== TRAINING_FORM_STATUS_SEND);

                if (activeForms.length > 0) {
                    // берем самую свежую
                    activeForms.sort((a, b) => (b.id || 0) - (a.id || 0));
                    setFormData(activeForms[0]);
                } else {
                    // иначе новая
                    setFormData(defaultFormData);
                }
            } catch (err) {
                console.error('Error loading forklift forms:', err);
                setFormData(defaultFormData);
            } finally {
                setLoading(false);
            }
        })();
    }, []);

    // Автосохранение
    const autoSaveForm = useCallback(async (updatedForm) => {
        try {
            const newId = await saveFtForm(updatedForm);
            setFormData(prev => (prev ? { ...prev, id: newId } : prev));
        } catch (err) {
            console.error('Auto-save forklift form error:', err);
        }
    }, []);

    // Если статус != FINISH => PROCESS
    const handleUpdateFormData = (updated) => {
        if (updated.status !== TRAINING_FORM_STATUS_FINISH) {
            updated.status = TRAINING_FORM_STATUS_PROCESS;
        }
        setFormData(updated);
        autoSaveForm(updated);
    };

    const handleInputChange = (key, value) => {
        if (!formData) return;
        const newData = { ...formData, [key]: value };
        handleUpdateFormData(newData);
    };

    // Изменение полей (section7 ... section13)
    const handleFieldChange = (section, key, value) => {
        if (!formData) return;
        const newSectionData = { ...formData[section], [key]: value };

        if (key === 'otherEnabled' && !value) {
            newSectionData.other = '';
        }

        const newData = { ...formData, [section]: newSectionData };
        handleUpdateFormData(newData);
    };

    // Отправка на сервер
    const handleSend = async () => {
        if (!formData) return;

        // 1) Проверяем обязательные поля
        if (!formData.operatorName) {
            alert("Please select an operator name before sending.");
            return;
        }

        if (!formData.trainerName) {
            alert("Please select a trainer name before sending.");
            return;
        }

        if (!formData.section14.success) {
            alert("The maneuvering practice drills have not been successfully completed");
            return;
        }

        if (!formData.signature) {
            alert("Signature is required to proceed.");
            return;
        }

        try {
            const dataToSend = {
                ...formData,
                formType: 'ft',
            };

            // Отправляем POST-запрос на /training/form/save
            await axiosInstance.post('/training/form/save', dataToSend);

            // Если есть id, помечаем SEND
            if (formData.id) {
                await markFtFormSend(formData.id);
            }
            // Очищаем
            setFormData({ ...defaultFormData });
            alert('Form sent successfully. New form started...');
        } catch (err) {
            console.error('Error sending forklift form', err);
            alert('Error sending forklift form to server.');
        }
    };

    // Сохранить подпись
    const handleSignatureSaved = (base64Signature) => {
        if (!formData) return;
        let newStatus = TRAINING_FORM_STATUS_PROCESS;
        if (base64Signature) {
            newStatus = TRAINING_FORM_STATUS_FINISH;
        }
        const newData = {
            ...formData,
            signature: base64Signature || null,
            status: newStatus
        };
        setFormData(newData);
        autoSaveForm(newData);
        setShowSignatureModal(false);
    };

    if (loading || !formData) {
        return <Typography>Loading forklift form...</Typography>;
    }

    return (
        <Box sx={{ p: 4 }}>
            <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2 }}>
                FORKLIFT PROFICIENCY / EVALUATION FORM
            </Typography>

            {/* Шапка */}
            <TrainingFormHeader
                onGoBack={'#/training'}
                operatorName={formData.operatorName}
                trainerName={formData.trainerName}
                date={formData.date}
                onInputChange={handleInputChange}
            />

            {/* Section 7 */}
            <TrainingFormSection
                title="Ability to demonstrate and explain proper use of controls"
                fields={[
                    { key: 'clutch', label: 'Clutch', type: 'checkbox' },
                    { key: 'tiltControl', label: 'Tilt control', type: 'checkbox' },
                    { key: 'attachmentControl', label: 'Attachment control', type: 'checkbox' },
                    { key: 'serviceBrake', label: 'Service brake and parking brake', type: 'checkbox' },
                    { key: 'inchingControl', label: 'Inching control (Automatic transmission)', type: 'checkbox' },
                    { key: 'liftControl', label: 'Lift Control', type: 'checkbox' },
                    { key: 'steeringTechniques', label: 'Steering techniques for type of machine', type: 'checkbox' },
                    { key: 'otherEnabled', label: 'Other', type: 'checkbox' },
                    { key: 'other', label: 'Other', type: 'text', conditionalOn: 'otherEnabled' },
                ]}
                values={formData.section7}
                onFieldChange={(key, value) => handleFieldChange('section7', key, value)}
            />

            {/* Section 8 */}
            <TrainingFormSection
                title=''
                fields={[
                    { key: 'within25Feet', label: 'Proper positioning of controls when within 25 feet from truck and in view.', type: 'checkboxLeft' },
                ]}
                values={formData.section8}
                onFieldChange={(key, value) => handleFieldChange('section8', key, value)}
            />

            {/* Section 8 */}
            <TrainingFormSection
                title=''
                fields={[
                    { key: 'over25FeetNotInView', label: 'Proper positioning of controls when over 25 feet from truck and not in view.', type: 'checkboxLeft' },
                ]}
                values={formData.section8}
                onFieldChange={(key, value) => handleFieldChange('section8', key, value)}
            />

            {/* Section 8 */}
            <TrainingFormSection
                title=''
                fields={[
                    { key: 'positionControlNotInView', label: 'Proper position of control when not in view regardless of distance.', type: 'checkboxLeft' },
                ]}
                values={formData.section8}
                onFieldChange={(key, value) => handleFieldChange('section8', key, value)}
            />

            {/* Section 8 */}
            <TrainingFormSection
                title=''
                fields={[
                    { key: 'useChocksOnIncline', label: 'Use of chocks when parked on an incline.', type: 'checkboxLeft' },
                ]}
                values={formData.section8}
                onFieldChange={(key, value) => handleFieldChange('section8', key, value)}
            />

            {/* Section 9 */}
            <TrainingFormSection
                title="Demonstration of proper forklift operation"
                fields={[
                    { key: 'properMounting', label: 'Proper mounting and dismounting', type: 'checkbox' },
                    { key: 'seatBelts', label: 'Use of seat belts', type: 'checkbox' },
                    { key: 'directionOfTravel', label: 'Looking in the direction of travel', type: 'checkbox' },
                    { key: 'carryingForksLowered', label: 'Carrying forks in lowered position', type: 'checkbox' },
                    { key: 'appropriateSpeeds', label: 'Appropriate speeds', type: 'checkbox' },
                    { key: 'carryingLoadUp', label: 'Carrying load up incline with load pointing uphill.', type: 'checkbox' },
                    { key: 'carryingLoadDown', label: 'Carrying load down incline with load pointing uphill.', type: 'checkbox' },
                    { key: 'adjustmentWetSurface', label: 'Proper adjustment to wet or uneven driving surface', type: 'checkbox' },
                    { key: 'cornering', label: 'Cornering', type: 'checkbox' },
                    { key: 'soundingHorn', label: 'Sounding horn at corners/intersections', type: 'checkbox' },
                    { key: 'otherEnabled', label: 'Other', type: 'checkbox' },
                    { key: 'other', label: 'Other', type: 'text', conditionalOn: 'otherEnabled' },
                ]}
                values={formData.section9}
                onFieldChange={(key, value) => handleFieldChange('section9', key, value)}
            />

            {/* Section 10 */}
            <TrainingFormSection
                title="Loading"
                fields={[
                    { key: 'properBalancing', label: 'Proper balancing of load', type: 'checkbox' },
                    { key: 'properSize', label: 'Proper size load for safe handling and visibility', type: 'checkbox' },
                    { key: 'properCapacity', label: 'Proper capacity for truck size and type', type: 'checkbox' },
                    { key: 'loadCradledBack', label: 'Load cradled back against backrest', type: 'checkbox' },
                    { key: 'otherEnabled', label: 'Other', type: 'checkbox' },
                    { key: 'other', label: 'Other', type: 'text', conditionalOn: 'otherEnabled' },
                ]}
                values={formData.section10}
                onFieldChange={(key, value) => handleFieldChange('section10', key, value)}
            />

            {/* Section 11 */}
            <TrainingFormSection
                title="Driving with load"
                fields={[
                    { key: 'maintainingProper', label: 'Maintaining proper speed for load size', type: 'checkbox' },
                    { key: 'centeringLoads', label: 'Centering loads on forks', type: 'checkbox' },
                    { key: 'HandlingLoad', label: 'Handling load to prevent harm to cargo.', type: 'checkbox' },
                    { key: 'smoothStartingBraking', label: 'Performing smooth starting and braking', type: 'checkbox' },
                    { key: 'travelingThreeForkLengths', label: 'Traveling at least three fork truck lengths behind other trucks', type: 'checkbox' },
                    { key: 'otherEnabled', label: 'Other', type: 'checkbox' },
                    { key: 'other', label: 'Other', type: 'text', conditionalOn: 'otherEnabled' },
                ]}
                values={formData.section11}
                onFieldChange={(key, value) => handleFieldChange('section11', key, value)}
            />

            {/* Section 12 */}
            <TrainingFormSection
                title="Unloading and Stacking"
                fields={[
                    { key: 'stackingSquarely', label: 'Stacking squarely and straight', type: 'checkbox' },
                    { key: 'depositingLoadSafely', label: 'Depositing load safely', type: 'checkbox' },
                    { key: 'properHeightTieredLoad', label: 'Proper height of tiered load', type: 'checkbox' },
                    { key: 'properlyDepositing', label: 'Properly depositing of load on rack', type: 'checkbox' },
                    { key: 'removingLoadTravel', label: 'Removing load & lowering to safe level before starting to travel', type: 'checkbox' },
                    { key: 'usingProper', label: 'Using proper fork spread when picking load.', type: 'checkbox' },
                    { key: 'removingLoadRack', label: 'Properly removing load from rack', type: 'checkbox' },
                    { key: 'otherEnabled', label: 'Other', type: 'checkbox' },
                    { key: 'other', label: 'Other', type: 'text', conditionalOn: 'otherEnabled' },
                ]}
                values={formData.section12}
                onFieldChange={(key, value) => handleFieldChange('section12', key, value)}
            />

            {/* Section 13 */}
            <TrainingFormSection
                title="Workplace conditions"
                fields={[
                    { key: 'demonstratesParking', label: 'Demonstrates proper forklift parking', type: 'checkbox' },
                    { key: 'demonstratesLoadPlacement', label: 'Demonstrates proper load placement', type: 'checkbox' },
                    { key: 'awareDangerousHazards', label: 'Aware of dangerous or hazardous areas', type: 'checkbox' },
                    { key: 'areasNotPlaceLoads', label: 'Aware of areas not to place loads', type: 'checkbox' },
                    { key: 'awareFireEquipment', label: 'Aware of fire equipment & appropriate eyewash & 1st aid facilities', type: 'checkbox' },
                    { key: 'awareEmployees', label: 'Aware of other employees', type: 'checkbox' },
                    { key: 'awareAisles', label: 'Aware of aisles for egress', type: 'checkbox' },
                    { key: 'awareExits', label: 'Aware of exits', type: 'checkbox' },
                    { key: 'otherEnabled', label: 'Other', type: 'checkbox' },
                    { key: 'other', label: 'Other', type: 'text', conditionalOn: 'otherEnabled' },
                ]}
                values={formData.section13}
                onFieldChange={(key, value) => handleFieldChange('section13', key, value)}
            />


            {/* Signature */}
            <Box
                sx={{
                    mt: 3,
                    p: 2,
                    border: '1px solid #ccc',
                    borderRadius: '8px',
                    backgroundColor: '#f9f9f9'
                }}
            >

                {/* Section 14 */}
                <TrainingFormSection
                    title=""
                    fields={[
                        { key: 'success', label: 'Successfully completed maneuvering practice drills.', type: 'checkboxLeft' }
                    ]}
                    values={formData.section14}
                    onFieldChange={(key, value) => handleFieldChange('section14', key, value)}
                />



                {formData.signature ? (
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', gap: 2 }}>
                        <img
                            src={formData.signature}
                            alt="Signature"
                            style={{ maxWidth: '200px', border: '1px solid #aaa' }}
                        />
                        <Button
                            variant="contained"
                            onClick={() => setShowSignatureModal(true)}
                        >
                            Change Signature
                        </Button>
                    </Box>
                ) : (
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                        <Button
                            variant="contained"
                            onClick={() => setShowSignatureModal(true)}
                        >
                            Add Signature
                        </Button>
                    </Box>
                )}



            </Box>





            {/* Кнопка SEND: только если status=FINISH */}
            <Box sx={{ textAlign: 'right', mt: 4 }}>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleSend}
                    disabled={formData.status !== TRAINING_FORM_STATUS_FINISH}
                >
                    Send
                </Button>
            </Box>

            {/* Модалка подписи */}
            {showSignatureModal && (
                <SignatureModal
                    existingSignature={formData.signature}
                    onClose={() => setShowSignatureModal(false)}
                    onSave={handleSignatureSaved}
                />
            )}
        </Box>
    );
}
