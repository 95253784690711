export const DB_NAME = 'dhgriffin';
export const DB_VERSION = 8;
export const EMPLOYEE_STORE = 'employees';
export const INSTRUCTION_STORE = 'instructions';
export const WEEKLY_INSTRUCTION_STORE = 'weeklyInstructions';
export const WEEK_LIST_STORE = 'weeklyList';
export const SIGNED_DOCS_STORE = 'signedDocuments';
export const TRAINING_DUE_STORE = 'trainingDue';
export const TRAINING_STORE = 'training';
export const MEP_FORM_STORE = 'mepForm';
export const FT_FORM_STORE = 'ftForm';
export const COST_CODE_STORE = 'costCode';
export const GL_CODE_STORE = 'glCode';
export const CC_STORE = 'ccPhoto';
export const JOB_STORE = 'job';
export const CODE_GROUP_STORE = 'codeGroup';
export const TRAINING_FORM_STATUS_PROCESS = 'process';
export const TRAINING_FORM_STATUS_FINISH = 'finish';
export const TRAINING_FORM_STATUS_SEND = 'send';
//export const URL = 'http://admin.dhgriffinapp.local/api';
export const URL = 'https://admin.dhgriffinapp.com/api';
//export const URL = 'http://dhgriffin.local/api';