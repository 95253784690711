import { useState, useEffect } from 'react';
import { useTheme, useMediaQuery } from '@mui/material';

function useOrientation() {
    const [isLandscape, setIsLandscape] = useState(window.innerWidth > window.innerHeight);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        const handleResize = () => {
            setIsLandscape(window.innerWidth > window.innerHeight);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return { isLandscape, isMobile };
}

export default useOrientation;
