// services/instructionService.js
import { initializeDB } from '../config/db';
import { WEEKLY_INSTRUCTION_STORE } from '../config/constants';

export const getInstructionById = async (id) => {
    const db = await initializeDB();
    const store = db.transaction(WEEKLY_INSTRUCTION_STORE, 'readonly').objectStore(WEEKLY_INSTRUCTION_STORE);
    return await store.get(id);
};

export const updateInstruction = async (instruction) => {
    const db = await initializeDB();
    const tx = db.transaction(WEEKLY_INSTRUCTION_STORE, 'readwrite');
    const store = tx.objectStore(WEEKLY_INSTRUCTION_STORE);
    await store.put(instruction);
    await tx.done;
};

export const saveEmployeeSignature = async (instructionUniqueId, employeeId, signatureData) => {
    const db = await initializeDB();
    const tx = db.transaction(WEEKLY_INSTRUCTION_STORE, 'readwrite');
    const store = tx.objectStore(WEEKLY_INSTRUCTION_STORE);

    const currentInstruction = await store.get(instructionUniqueId);
    if (!currentInstruction) throw new Error('Instruction not found');

    const employeeIndex = currentInstruction.signedEmployees.findIndex(emp => emp.id === employeeId);
    if (employeeIndex > -1) {
        currentInstruction.signedEmployees[employeeIndex].signature = signatureData;
        currentInstruction.signedEmployees[employeeIndex].signedAt = new Date().toISOString();
        await store.put(currentInstruction);
        return currentInstruction;
    } else {
        throw new Error('Employee not found in signedEmployees');
    }
};
