import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, Box } from '@mui/material';
import { syncAllData } from '../services/syncService';
import backgroundImage from '../assets/image.jpg';
import logo from "../assets/logo.png";
import {URL} from "../config/constants";
const LoginPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const handleLogin = (e) => {
        e.preventDefault();

        fetch(URL+'/login', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email, password })
        })
            .then(response => response.json())
            .then(data => {
                if (data.token) {
                    console.log(data);

                    localStorage.setItem('token', data.token);
                    localStorage.setItem('refreshToken', data.refresh_token);

                    fetch(URL+'/user', {
                        method: 'GET',
                        headers: {
                            'Authorization': `Bearer ${data.token}`,
                            'Content-Type': 'application/json'
                        }
                    })
                        .then(response => response.json())
                        .then(userData => {
                            localStorage.setItem('firstName', userData.firstName);
                            localStorage.setItem('userId', userData.userId);
                            localStorage.setItem('employeeId', userData.employeeId);
                            localStorage.setItem('lastName', userData.lastName);
                            localStorage.setItem('roles', JSON.stringify(userData.roles));

                            syncAllData();

                            navigate('/main');
                        })
                        .catch(err => console.error('Error fetching user data:', err));
                } else {
                    alert('Login failed');
                }
            })
            .catch(err => console.error('Error logging in:', err));
    };

    return (
        <Box
            sx={{
                position: 'relative',
                width: '100vw',
                height: '100vh',
                overflow: 'hidden',
            }}
        >
            <Box
                component="img"
                src={backgroundImage}
                alt="Background"
                sx={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    top: 0,
                    left: 0,
                    objectFit: 'cover',
                    zIndex: -1,
                }}
            />
            <Box
                sx={{
                    position: 'relative',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%',
                    padding: '20px',
                }}
            >
                <Box
                    sx={{
                        backgroundColor: 'rgba(255, 255, 255, 0.9)',
                        padding: '40px',
                        borderRadius: '12px',
                        boxShadow: '0px 8px 30px rgba(0, 0, 0, 0.2)',
                        maxWidth: '400px',
                        width: '100%',
                        margin: '0 auto',
                    }}
                >
                    <><img src={logo} alt="Logo" style={{maxHeight: '140px',margin:"10px auto", display:"block"}}/></>
                    <form onSubmit={handleLogin}>
                        <TextField
                            label="Email"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            sx={{backgroundColor: 'white'}}
                        />
                        <TextField
                            label="Password"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            sx={{backgroundColor: 'white'}}
                        />
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            fullWidth
                            sx={{
                                marginTop: '30px',
                                padding: '12px',
                                fontSize: '1rem',
                            }}
                        >
                            Login
                        </Button>
                    </form>
                </Box>
            </Box>
        </Box>
    );
};

export default LoginPage;
