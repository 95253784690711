import React from 'react';
import {
    Container,
    Grid,
} from '@mui/material';
import {useMainPageStatuses} from "../../hooks/useMainPageStatuse";

import {option} from "./Option";
import {CardOption} from "./CardOption";



export default function MainPage() {
    // Подтягиваем статусы для TBT и CC
    const { tbtStatuses, ccStatus } = useMainPageStatuses();

    return (
        <Container maxWidth={false} sx={{ py: 3, width: '100%' }}>
            <Grid container spacing={4} justifyContent="center" alignItems="center">
                {option.map((option) => (
                    <CardOption
                        key={option.name}
                        name={option.name}
                        icon={option.icon}
                        link={option.link}
                        tbtStatuses={tbtStatuses}
                        ccStatus={ccStatus}
                    />
                ))}
            </Grid>
        </Container>
    );
}
