export const formatSyncTime = (time) => {
    if (!time || time === 'Never') return 'Never';

    const parsedDate = new Date(time);
    if (isNaN(parsedDate)) {
        console.error("Error formatting date - Invalid Date:", time);
        return 'Invalid Date';
    }

    return parsedDate.toLocaleString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
    });
};
