import React from 'react';
import { Box, Typography } from '@mui/material';
import AssignmentIcon from '@mui/icons-material/Assignment';

const InstructionsCount = ({ processedCount, newCount, showProcessed }) => (
    <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{
            backgroundColor: showProcessed ? '#f5f5f5' : '#e0f7fa',
            padding: '5px 10px',
            borderRadius: '20px',
            width: '150px',
            transition: 'background-color 0.3s ease, color 0.3s ease',
        }}
    >
        <AssignmentIcon
            fontSize="small"
            sx={{
                marginRight: '5px',
                color: showProcessed ? 'purple' : '#00796b',
                transition: 'color 0.3s ease',
            }}
        />
        <Typography
            variant="body2"
            color={showProcessed ? 'purple' : '#00796b'}
            sx={{ transition: 'color 0.3s ease' }}
        >
            {showProcessed ? `${processedCount} Processed` : `${newCount} New`}
        </Typography>
    </Box>
);

export default React.memo(InstructionsCount);
